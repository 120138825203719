// Modules
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

// CSS and assets
import './style.scss';

// Components
import AccountLogin from '@pages/checkout/components/AccountLogin';
import AccountCreate from '@pages/checkout/components/AccountCreate';
import OrderSummary from '@pages/checkout/components/OrderSummary';
import BillingInformation from '@pages/checkout/components/BillingInformation';
import SecurePayment from '@pages/checkout/components/SecurePayment';
import { ReactComponent as LockIcon } from '@assets/images/lock_keyhole.svg';

// GraphQL
import { REQUEST_CART, CART_BY_CART_ID, LINK_USER_TO_CART } from './queries';

// Utils
import * as Constants from '@global/App/constants/constants';
import { mapToView } from './helper';
import getOrderPrice from '@global/hooks/getOrderPrice';
import UserService from '@global/Api/keycloak/UserService';
import { Keycloak } from '@global/App/constants/constants';

// Types
import { CustomerOrderBiId } from '@src/global/types/types';

const Checkout: React.FC = () => {
  const [userLinked, setUserLinked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cart_id = localStorage.getItem(Constants.CART_ID_ATTRIBUTE);
  // Checkout step 1 content
  const [stepOne, setStepOne] = useState(false);
  // Checkout step 2 content
  const [stepTwo, setStepTwo] = useState(true);
  // Checkout step 3 content
  const [stepThree, setStepThree] = useState(false);
  // Currently logged in user
  const [user, setUser] = useState('');
  // Login form password field toggle SHOW/HIDE
  const [loginFormVisibility, setLoginFormVisibility] = useState(true);
  // Toggle which form shows on step 1.
  const [createAccountFormVisibility, setCreateAccountFormVisibility] =
    useState(false);
  // Confirmation for step 2, stating the user agrees to pay fees
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [checkoutValid, setCheckoutValid] = useState(false);
  // State management for address 2 field. Needs to be controlled by parent to display state value.
  const [addressTwoVisibility, setAddressTwoVisibility] = useState(false);
  // Error states for checkout forms
  const [errors, setErrors] = useState({
    stepOne: {
      loginInfo: false,
      createEmail: false,
    },
    stepTwo: {
      nameCC: false,
    },
    stepThree: {
      invalidDetails: false,
    },
  });
  // Persistent user info to be displayed in step 2
  const [billingInfo, setBillingInfo] = useState({
    name: '',
    tel: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    state: '',
    zip: '',
  });

  let orderPrice: {
    total: number;
    tax: number;
  } = {
    total: 0,
    tax: 0,
  };
  // States related to promo code application and visibility in order summary
  const [promoCode, setPromoCode] = useState('');
  const [validPromo, setValidPromo] = useState<null | React.ReactNode>(null);
  const [promoError, setPromoError] = useState(false);

  // Scroll to top of page on render.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // check if user already specified billing information to current card
  if (cart_id) {
    useQuery(CART_BY_CART_ID, {
      variables: {
        biId: cart_id,
      },
      onCompleted: (data) => {
        if (data.getCustomerOrderByBiId.paybBillingAddress) {
          // Billing info exist go to step three
          setBillingInfo({
            name: data.getCustomerOrderByBiId.paybPayment?.ccpaNameOnCard || '',
            tel: data.getCustomerOrderByBiId.wContactPhone || '',
            ...mapToView(data.getCustomerOrderByBiId.paybBillingAddress),
          });
          setAgreementCheck(data.getCustomerOrderByBiId.wTOSAccepted);
          setStepThree(true);
          setStepTwo(false);
        }
      },
    });
  }

  // Cart instance query. Contents will be retrieved from cache.
  const { data: cartData, loading: loadingCartData } = useQuery(REQUEST_CART, {
    variables: {
      biId: cart_id ? cart_id : '',
    },
  }) as { data: CustomerOrderBiId; loading: boolean };

  const [mutateFunction] = useMutation(LINK_USER_TO_CART);

  useEffect(() => {
    const linkCartWithUser = async () => {
      if (UserService.isLoggedIn()) {
        await mutateFunction({
          variables: {
            biId: cart_id ? cart_id : '',
            paId: UserService.getUserUid(),
          },
        });
        // uid is now available inside biiProduct
      }
    };

    if (cartData?.getCustomerOrderByBiId && user && !userLinked) {
      linkCartWithUser();
      setUserLinked(true);
    }
  }, [cartData, user]);

  useEffect(() => {
    if (stepThree) {
      document.getElementById('securePayment').scrollIntoView();
    }
  }, [stepThree]);

  const cartContents = cartData?.getCustomerOrderByBiId?.hiConsistsOf;
  const orderDataSummary =
    cartContents &&
    cartContents.map((item) => {
      return {
        item: item.biiProduct.name,
        price: item.biiProduct.pricPrice['edges'][0].node.amount,
      };
    });

  if (cartData?.getCustomerOrderByBiId?.pricPrice) {
    orderPrice = getOrderPrice(
      cartData.getCustomerOrderByBiId.pricPrice?.edges
    );
  }

  const editStepHandler = (step: number) => {
    switch (step) {
      case 1:
        setStepOne(true);
        setStepTwo(false);
        setStepThree(false);
        setLoginFormVisibility(true);
        setCreateAccountFormVisibility(false);
        break;
      case 2:
        setStepOne(false);
        setStepTwo(true);
        setStepThree(false);
        break;
      case 3:
        setStepOne(false);
        setStepTwo(false);
        setStepThree(true);
        break;
    }
  };

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    UserService.isLoggedIn()
  );

  if (!isUserLoggedIn) {
    // UserService.doLogin({
    //   idpHint: Keycloak.DEFAULT_IDP,
    // });
    setIsUserLoggedIn(true);
    setUser(UserService.getUserEmail());
  }

  if (isUserLoggedIn && !user) {
    setUser(UserService.getUserEmail());
  }

  return (
    <>
      <div className="order-summary-mobile">
        <OrderSummary
          orderItems={orderDataSummary}
          stepOne={stepOne}
          stepTwo={stepTwo}
          stepThree={stepThree}
          agreementCheck={agreementCheck}
          orderPrice={orderPrice}
          isSummaryLoading={loadingCartData}
          isLoading={isLoading}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          validPromo={validPromo}
          setValidPromo={setValidPromo}
          promoError={promoError}
          setPromoError={setPromoError}
        />
      </div>

      <div className="checkout">
        <div className="checkout__container">
          <div className="checkout__steps">
            {/* CHECKOUT STEP 1 */}
            {stepOne ? (
              <span className="checkout__title">
                <span className="checkout__title-num">1.</span>
                {loginFormVisibility ? (
                  <span>Account Login</span>
                ) : (
                  <span>Create Account</span>
                )}
              </span>
            ) : (
              <span className="checkout__title--inactive">
                <span className="checkout__title-num">1.</span>
                <span>Subscription Account</span>
                <button
                  className="checkout__edit-btn"
                  disabled
                  onClick={() => editStepHandler(1)}
                >
                  Edit
                </button>
              </span>
            )}
            {stepOne ? (
              <>
                {loginFormVisibility && (
                  <AccountLogin
                    props={{
                      setUser,
                      setLoginFormVisibility,
                      setCreateAccountFormVisibility,
                      editStepHandler,
                      errors,
                      setErrors,
                    }}
                  />
                )}
                {createAccountFormVisibility && (
                  <AccountCreate
                    props={{
                      setUser,
                      setLoginFormVisibility,
                      setCreateAccountFormVisibility,
                      editStepHandler,
                      errors,
                      setErrors,
                    }}
                  />
                )}
              </>
            ) : (
              <span className="checkout__current-user regularText">
                You are currently signed in as {<span>{user}</span>}
              </span>
            )}

            {/* CHECKOUT STEP 2 */}
            <span
              className={
                stepTwo ? 'checkout__title' : 'checkout__title--inactive'
              }
            >
              <span className="checkout__title-num">2.</span>
              <span>Billing Information</span>
              {stepThree && (
                <button
                  className="checkout__edit-btn"
                  onClick={() => editStepHandler(2)}
                >
                  Edit
                </button>
              )}
            </span>
            {stepTwo && (
              <BillingInformation
                agreementCheck={agreementCheck}
                setAgreementCheck={setAgreementCheck}
                checkoutValid={checkoutValid}
                setCheckoutValid={setCheckoutValid}
                editStepHandler={editStepHandler}
                billingInfo={billingInfo}
                setBillingInfo={setBillingInfo}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                addressTwoVisibility={addressTwoVisibility}
                setAddressTwoVisibility={setAddressTwoVisibility}
              />
            )}
            {stepThree && (
              <div id={`securePayment`} className="checkout__user-info">
                <span className="regularText">
                  Your billing information is used to verify your credit card,
                  or contact you if there are any issues placing your order.
                </span>
                <span>{billingInfo.name}</span>
                <span>{billingInfo.tel}</span>
                <span>{billingInfo.addressOne}</span>
                {billingInfo.addressTwo && (
                  <span>{billingInfo.addressTwo}</span>
                )}
                <span>
                  {billingInfo.city && `${billingInfo.city},`}{' '}
                  {billingInfo.state} {billingInfo.zip}
                </span>
              </div>
            )}

            {/* CHECKOUT STEP 3 */}
            <span
              className={
                stepThree ? 'checkout__title' : 'checkout__title--inactive'
              }
            >
              <span className="checkout__title-num">3.</span>
              <span>Secure Payment</span>
              <LockIcon
                className={
                  stepThree
                    ? 'checkout__lock-icon'
                    : 'checkout__lock-icon--inactive'
                }
              />
            </span>
            {stepThree && (
              <SecurePayment
                errors={errors}
                setErrors={setErrors}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
          </div>

          <div className="order-summary-desktop">
            <OrderSummary
              orderItems={orderDataSummary}
              stepOne={stepOne}
              stepTwo={stepTwo}
              stepThree={stepThree}
              agreementCheck={agreementCheck}
              orderPrice={orderPrice}
              isSummaryLoading={loadingCartData}
              isLoading={isLoading}
              promoCode={promoCode}
              setPromoCode={setPromoCode}
              validPromo={validPromo}
              setValidPromo={setValidPromo}
              promoError={promoError}
              setPromoError={setPromoError}
              checkoutValid={checkoutValid}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
