// Modules
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import i18n from 'i18next';

// CSS and assets
import '@pages/checkout/components/SecurePayment/style.scss';

//Components
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ButtonCTA from '@src/global/components/UI Elements/ButtonCTA';

//Graphql
import { PLACE_CUSTOMER_ORDER_MUTATION } from '@pages/checkout/queries';
import { ORDER_BY_CART_ID } from './queries';
import { prefixPath } from '@global/localization/services/services';

// Utils
import { Pages, CART_ID_ATTRIBUTE } from '@global/App/constants/constants';

interface IProps {
  errors: {
    stepOne: {
      loginInfo: boolean;
      createEmail: boolean;
    };
    stepTwo: {
      nameCC: boolean;
    };
    stepThree: {
      invalidDetails: boolean;
    };
  };
  setErrors: React.Dispatch<
    React.SetStateAction<{
      stepOne: {
        loginInfo: boolean;
        createEmail: boolean;
      };
      stepTwo: {
        nameCC: boolean;
      };
      stepThree: {
        invalidDetails: boolean;
      };
    }>
  >;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const paymentElementsStyling = {
  style: {
    base: {
      color: '#262626',
      fontWeight: '400',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#262626',
      },
      '::placeholder': {
        color: '#262626',
      },
    },
    invalid: {
      color: '#262626',
    },
  },
  hidePostalCode: true,
};

// const SUCCES_RESULT = 'success';
const CheckoutForm: React.FC<IProps> = ({
  isLoading,
  setIsLoading,
  setErrors,
}) => {
  const [cartId, setCartId] = useState<string | null>(null);
  const [startWaiter, setStartWaiter] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [placeCustomerOrder] = useMutation(PLACE_CUSTOMER_ORDER_MUTATION);

  const [message, setMessage] = useState<string | null>(null);

  const history = useHistory();

  // Instance of cart id
  useEffect(() => {
    const cartId = localStorage.getItem(CART_ID_ATTRIBUTE);
    if (cartId) {
      setCartId(cartId);
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!error) {
      if (paymentMethod?.id) {
        const placeCustomerOrderResult = await placeCustomerOrder({
          variables: {
            biId: cartId,
            token: paymentMethod?.id,
          },
        });
        if (placeCustomerOrderResult.errors) {
          console.log(`errors`);
          console.log(placeCustomerOrderResult.errors);
        }
        setStartWaiter(true);
      }
    } else {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message as string);
      } else {
        setMessage('An unexpected error occured.');
      }
      setIsLoading(false);
    }
    if (error) {
      setErrors((prev) => {
        return {
          ...prev,
          stepThree: {
            invalidDetails: true,
          },
        };
      });
      setIsLoading(false);
    }
  };

  useQuery(ORDER_BY_CART_ID, {
    variables: {
      biId: cartId,
    },
    pollInterval: 1500,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !startWaiter || !cartId,
    onCompleted: (data) => {
      if (data?.getCustomerOrderByBiId?.bpStatus?.code === 'Placed') {
        setStartWaiter(false);
        setIsLoading(false);
        localStorage.setItem('orderId', data?.getCustomerOrderByBiId?.biId);
        localStorage.removeItem(CART_ID_ATTRIBUTE);
        history.push(prefixPath(Pages.ORDER_CONFIRMATION, i18n.language));
      }
      if (data?.getCustomerOrderByBiId?.bpStatus?.code === 'PaymentError') {
        setStartWaiter(false);
        setIsLoading(false);
        setMessage('Payment error');
      }
    },
  });

  return (
    <form className="payment-form" id="payment-form" onSubmit={handleSubmit}>
      <div className="payment-form__stripe-wrapper">
        <CardElement
          options={paymentElementsStyling}
          onReady={(element) => element.focus()}
        />
      </div>
      {/* Show any error or success messages */}
      {message && <span className="payment-form__error-msg">{message}</span>}
      <span className="payment-form__agreement">
        By clicking Submit My Order, I agree to the{' '}
        <span className="payment-form__agreement--link">
          Terms & Conditions
        </span>
        .
      </span>
      <ButtonCTA
        variant="primary"
        type="submit"
        className="payment-form__submit-btn"
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            'Submit My Order'
          )}
        </span>
      </ButtonCTA>
    </form>
  );
};

export default CheckoutForm;
