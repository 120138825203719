interface PaybBillingAddress {
  upaLine1: string;
  upaLine2: string;
  upaCity: string;
  gadStateOrProvince: string;
  upaPostcode: string;
  uid?: string;
}

interface PaybBillingAddressView {
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zip: string;
  uid?: string;
}

export function mapToView(
  paybBillingAddress: PaybBillingAddress = {
    upaLine1: '',
    upaLine2: '',
    upaCity: '',
    gadStateOrProvince: '',
    upaPostcode: '',
  }
): PaybBillingAddressView {
  return {
    state: paybBillingAddress.gadStateOrProvince,
    addressOne: paybBillingAddress.upaLine1,
    addressTwo: paybBillingAddress.upaLine2,
    city: paybBillingAddress.upaCity,
    zip: paybBillingAddress.upaPostcode,
    uid: paybBillingAddress.uid,
  };
}
