// Moodules
import React from 'react';
import { Link } from 'react-router-dom';

// CSS and assets
import './style.scss';

const Page404: React.FC = () => {
  return (
    <div className="page404">
      <span className="page404__message">Page not found (404)</span>

      <div className="page404__help">
        <span>
          Our apologies. We can&apos;t find the page you are looking for.
        </span>
        <span>
          For further assistance, please feel free to{' '}
          <Link to={'/about'} className="page404__help-link">
            contact us
          </Link>
          .
        </span>
      </div>
    </div>
  );
};

export default Page404;
