// Modules
import React from 'react';
import { NavLink } from 'react-router-dom';

// CSS and assets
import './style.scss';

// Components
import ButtonCheckout from '@global/components/UI Elements/ButtonCheckout';

// Utils
import { matchCurrentLocation } from '@src/global/utils';
import { Pages } from '@global/App/constants/constants';
import { prefixPath } from '@global/localization/services/services';
import i18n from 'i18next';

const SecondaryNav: React.FC = () => {
  const navLinks = [
    {
      title: 'Chemistry on Demand',
      to: prefixPath(Pages.PDP1, i18n.language),
    },
    {
      title: 'Wiley Benefits',
      to: prefixPath(Pages.PDP2, i18n.language),
    },
    {
      title: 'VitalSource Bookshelf',
      to: prefixPath(Pages.PDP3, i18n.language),
    },
  ];
  return (
    !matchCurrentLocation(Pages.CHECKOUT) && (
      <nav className="navigation">
        <div className="navigation__container">
          <ul className="navigation__items">
            {navLinks.map((navItem) => {
              return (
                <li className="navigation__item-wrapper" key={navItem.title}>
                  <NavLink
                    activeClassName="active"
                    className="navigation__item"
                    to={navItem.to}
                  >
                    {navItem.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>

          <ButtonCheckout text={`CTA Checkout`} />
        </div>
      </nav>
    )
  );
};

export default SecondaryNav;
