// Modules
import React, { useState } from 'react';

// Css and assets
import './style.scss';
import { ReactComponent as UserIcon } from '@assets/images/User.svg';
import { ReactComponent as GlobeIcon } from '@assets/images/Globe.svg';
import { ReactComponent as ChevronIcon } from '@assets/images/Chevron.svg';

// Components
import UserDropdown from './components/UserDropdown';
import LanguageDropdown from './components/LanguageDropdown';

// Utils
import UserService from '@global/Api/keycloak/UserService';
import SecondaryNav from '@global/components/Header/components/SecondaryNav';
import Burger from '@global/components/Header/components/Burger';

interface IProps {
  isLogged: boolean;
}

const MenuNavigation: React.FC<IProps> = ({ isLogged }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const openMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      <div className="nav-menu">
        <div className="link-icon account-icon">
          {isLogged ? (
            <span className="user-icon">
              {UserService.getUserFirstName()
                ? UserService.getUserFirstName().substring(0, 1)
                : ''}
              {UserService.getUserLastName()
                ? UserService.getUserLastName().substring(0, 1)
                : ''}
            </span>
          ) : (
            <UserIcon className="icon-svg" />
          )}
          <UserDropdown isLogged={isLogged} />
        </div>

        <div className="link-icon">
          <GlobeIcon className="icon-svg" />
          <ChevronIcon className="icon-svg languages-expand-icon" />
          <LanguageDropdown />
        </div>
      </div>

      <div className="nav-menu__mobile-menu" onClick={openMenu}>
        <span>{menuOpen ? 'Close menu' : 'Menu'}</span>
        <Burger isOpen={menuOpen} />
      </div>

      <div className={menuOpen ? 'mobile-nav-menu open' : 'mobile-nav-menu'}>
        <SecondaryNav />
        <div className="section-extra">
          <div className="section-user">
            <UserDropdown isLogged={isLogged} />
          </div>
          <div className="section-language">
            <span>Language</span>
            <ChevronIcon className="icon-svg languages-expand-icon" />
            <LanguageDropdown />
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuNavigation;
