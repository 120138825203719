import React, { useState } from 'react';
import './style.scss';

const ButtonShow: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const showInputPassword = (e) => {
    e.target.parentElement.querySelector('input').type = showPassword
      ? 'password'
      : 'text';
    setShowPassword(!showPassword);
  };
  return (
    <span className="show-password-btn" onClick={showInputPassword}>
      {showPassword ? 'Hide' : 'Show'}
    </span>
  );
};

export default ButtonShow;
