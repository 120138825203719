import React, { useState, useEffect } from 'react';
import '@pages/checkout/components/AccountCreate/style.scss';

interface IProps {
  props: {
    setUser: React.Dispatch<React.SetStateAction<string>>;
    setLoginFormVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    setCreateAccountFormVisibility: React.Dispatch<
      React.SetStateAction<boolean>
    >;
    editStepHandler: (step: number) => void;
    errors: {
      stepOne: {
        loginInfo: boolean;
        createEmail: boolean;
      };
      stepTwo: {
        nameCC: boolean;
      };
      stepThree: {
        invalidDetails: boolean;
      };
    };
    setErrors: React.Dispatch<
      React.SetStateAction<{
        stepOne: {
          loginInfo: boolean;
          createEmail: boolean;
        };
        stepTwo: {
          nameCC: boolean;
        };
        stepThree: {
          invalidDetails: boolean;
        };
      }>
    >;
  };
}

const AccountCreate: React.FC<IProps> = ({
  props: {
    setUser,
    setLoginFormVisibility,
    setCreateAccountFormVisibility,
    editStepHandler,
    errors,
    setErrors,
  },
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPasswordToggle, setConfirmPasswordToggle] = useState(false);

  // Clear errors on component render
  useEffect(() => {
    setErrors((prev) => {
      return {
        ...prev,
        stepOne: {
          loginInfo: false,
          createEmail: false,
        },
      };
    });
  }, []);

  const sectionToggleHandler = () => {
    setLoginFormVisibility(true);
    setCreateAccountFormVisibility(false);
  };

  const passwordVisibilityHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    const buttonEl = e.target as HTMLButtonElement;
    const inputEl = buttonEl.previousElementSibling as HTMLInputElement;
    if (inputEl.attributes[1].value === 'checkout-createaccount-password') {
      setPasswordToggle((prev) => {
        return !prev;
      });
      if (inputEl.type === 'password') {
        inputEl.type = 'text';
      } else if ((inputEl.type = 'text')) {
        inputEl.type = 'password';
      }
    } else if (
      inputEl.attributes[1].value === 'checkout-createaccount-passwordconfirm'
    ) {
      setConfirmPasswordToggle((prev) => {
        return !prev;
      });
      if (inputEl.type === 'password') {
        inputEl.type = 'text';
      } else if ((inputEl.type = 'text')) {
        inputEl.type = 'password';
      }
    }
  };

  // Note: barebones functionality and no validation for demo purposees
  const formSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    // Update later to check for valid email/password
    if (email.toLocaleLowerCase() === 'error@error') {
      setErrors((prev) => {
        return {
          ...prev,
          stepOne: {
            loginInfo: prev.stepOne.loginInfo,
            createEmail: true,
          },
        };
      });
    } else if (email) {
      setErrors((prev) => {
        return {
          ...prev,
          stepOne: {
            loginInfo: prev.stepOne.loginInfo,
            createEmail: false,
          },
        };
      });
      console.log('Form Submitted Successfully');
      editStepHandler(2);
      setUser(email);
    }
  };

  return (
    <div className="create-account">
      <button className="existing-login-btn" onClick={sectionToggleHandler}>
        Log in to existing account
      </button>

      <span className="form-text">*Required Fields</span>

      <form
        className="create-account__form"
        onSubmit={(e) => formSubmitHandler(e)}
      >
        {/* NAME */}
        <div className="create-account__name-field">
          <label htmlFor="checkout-createaccount-name">*Name:</label>
          <input
            type="text"
            id="checkout-createaccount-name"
            name="checkout-createaccount-name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        {/* EMAIL */}
        <div className="create-account__email-field">
          <label htmlFor="checkout-createaccount-email">*Email Address:</label>
          <input
            className={
              errors.stepOne.createEmail && 'create-account__email-field--error'
            }
            type="email"
            id="checkout-createaccount-email"
            name="checkout-createaccount-email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.stepOne.createEmail && (
            <span className="create-account__error-msg">
              Email address is already in use.
            </span>
          )}
        </div>

        {/* CONFIRM EMAIL */}
        <div
          className="create-account__confirm-email-field"
          style={{
            alignSelf: `${errors.stepOne.createEmail ? 'start' : 'end'}`,
          }}
        >
          <label htmlFor="checkout-createaccount-emailconfirm">
            *Confirm Email Address:
          </label>
          <input
            type="email"
            id="checkout-createaccount-emailconfirm"
            name="checkout-createaccount-emailconfirm"
            value={confirmEmail}
            onChange={(e) => setConfirmEmail(e.target.value)}
          />
        </div>

        {/* PASSWORD */}
        <div className="create-account__password-field">
          <label htmlFor="checkout-createaccount-password">*Password:</label>
          <div>
            <input
              type="password"
              id="checkout-createaccount-password"
              name="checkout-createaccount-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={(e: React.MouseEvent) => passwordVisibilityHandler(e)}
            >
              {passwordToggle ? 'Hide' : 'Show'}
            </button>
          </div>
        </div>

        {/* CONFIRM PASSWORD */}
        <div className="create-account__confirm-password-field">
          <label htmlFor="checkout-createaccount-passwordconfirm">
            *Confirm Password:
          </label>
          <div>
            <input
              type="password"
              id="checkout-createaccount-passwordconfirm"
              name="checkout-createaccount-passwordconfirm"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={(e: React.MouseEvent) => passwordVisibilityHandler(e)}
            >
              {confirmPasswordToggle ? 'Hide' : 'Show'}
            </button>
          </div>
        </div>

        <span className="create-account__info">
          Your password must contain a mix of uppercase letters, lowercase
          letters, numbers, and special characters (e.g. ! @ # $ % ...)
        </span>

        <button className="create-account__submit-btn" type="submit">
          Create Account
        </button>
      </form>
    </div>
  );
};

export default AccountCreate;
