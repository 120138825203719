import { gql } from '@apollo/client';

// Create a new cart instance and adds a product to it.
export const NEW_CART = gql`
  mutation minimalCreateCartExample($skuId: String!) {
    createViaxCustomerOrder(
      input: {
        hiConsistsOf: [
          {
            typeName: "ViaxSubscriptionContractItem"
            biiProduct: { sku: $skuId }
          }
        ]
      }
    ) {
      biId
      id
      biCreatedAt
      hiConsistsOf {
        biiId
        biiSequentialId
        biiProduct {
          uid
          sku
          name
          imageUrl
          wBookPublishDate
          wBookAuthors {
            uid
            wName
          }
        }
      }
      pricPrice {
        edges {
          mapsOn {
            code
          }
          node {
            amount
            units {
              code
            }
          }
        }
      }
    }
  }
`;

// Add a product to cart if cart instance already exists.
export const UPDATE_CART = gql`
  mutation updateCartExample01(
    $biId: String!
    $contents: [UpdateCustomerOrderItemAlongWithViaxCustomerOrderInput]!
  ) {
    updateViaxCustomerOrder(input: { biId: $biId, hiConsistsOf: $contents }) {
      biId
      id
      biCreatedAt
      hiConsistsOf {
        biiId
        biiSequentialId
        biiProduct {
          uid
          sku
          name
          imageUrl
          wBookPublishDate
          wBookAuthors {
            uid
            wName
          }
        }
      }
      pricPrice {
        edges {
          mapsOn {
            code
          }
          node {
            amount
            units {
              code
            }
          }
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query getSubscriptionsForCustomer(
    $filter: String!
    $orderBy: [FilterSubscriptionContractOrderByInput!]
  ) {
    filterViaxSubscriptionContractBy(
      _filter: $filter
      _page: { page: 1, limit: 100 }
      _orderBy: $orderBy
    ) {
      page {
        biId
        bpStatus {
          code
        }
        paybNextBillDate
        subsTerminationDate
        hiConsistsOf {
          biiProduct {
            sku
          }
          paybPayment {
            paymAmount(_mapsOn: [{ code: "TOTAL" }]) {
              edges {
                mapsOn {
                  code
                }
                node {
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;
