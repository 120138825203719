import Keycloak from 'keycloak-js';
import * as Constants from '@global/App/constants/constants';

// const _kc = Keycloak({
//   realm: Constants.Keycloak.REALM,
//   clientId: Constants.Keycloak.CLIENT_ID,
//   url: Constants.Keycloak.AUTH_URL,
// });

// const initKeycloak = (onAuthenticatedCallback) => {
//   _kc
//     .init({
//       onLoad: 'check-sso',
//     })
//     .then(() => {
//       onAuthenticatedCallback();
//     });
// };

const doLogin = () => {
  return true;
};

const doLogout = () => {
  return true;
};

const getToken = () => 'asdfdswdfassdfasdfasdf';

const isLoggedIn = () => false;

const getUserEmail = (): string => 'WaDasd@adssdasd';

const getUserFirstName = (): string => 'asdasda';

const getUserLastName = (): string => 'asdsadas';

const getUserUid = (): string => '123test';

const UserService = {
  //initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getUserEmail,
  getUserFirstName,
  getUserLastName,
  getUserUid,
};
//const UserService = true;
export default UserService;
