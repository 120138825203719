// Modules
import React from 'react';
import { Link } from 'react-router-dom';

// CSS and assets
import './style.scss';

interface IProps {
  itemEntity: {
    imgUrl: string;
    biiId?: string;
    navEntity: {
      to: string;
      isInAppNav?: boolean;
      label: string;
    };
    bottomContent: {
      top: {
        left?: string;
        right?: string;
      };
      bottom: {
        left?: string;
        right?: string;
      };
    };
  };
  isCartPage?: boolean;
  active?: boolean;
  removeFromCartHandler?: (e: React.MouseEvent) => void;
}

const SubscriptionsBookItem: React.FC<IProps> = ({
  itemEntity: { imgUrl, navEntity, bottomContent, biiId },
  active = true,
  isCartPage,
  removeFromCartHandler,
}) => {
  return (
    <div className="book-item" data-biiid={biiId}>
      {isCartPage && (
        <div className="book-item__x-btn" onClick={removeFromCartHandler} />
      )}

      <div className="book-item__info-main">
        <div className="book-item__info-main-image">
          <img src={imgUrl as string | undefined} alt="Wiley Book Cover" />
        </div>

        <Link to={`/${navEntity.to}`} className="book-item__title-mobile">
          <span>{navEntity.label}</span>
        </Link>
      </div>

      <div className="book-item__content">
        <Link to={`/${navEntity.to}`} className="book-item__title">
          <span>{navEntity.label}</span>
        </Link>

        <div className="book-item__info-wrapper">
          <div className="book-item__date">
            <span>{bottomContent.top.left as string | undefined}</span>
            <span>{bottomContent.bottom.left as string | undefined}</span>
          </div>
          {active && (
            <div className="book-item__price">
              <span>{bottomContent.top.right as string | undefined}</span>
              <span>${bottomContent.bottom.right as string | undefined}</span>
            </div>
          )}
        </div>

        {isCartPage && (
          <span
            className="book-item__remove-btn"
            onClick={(e) => removeFromCartHandler(e)}
          >
            Remove from Cart
          </span>
        )}
      </div>
    </div>
  );
};

export default SubscriptionsBookItem;
