import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

// CSS and assets
import '@pages/orderConfirmation/style.scss';
import { ReactComponent as CheckmarkIcon } from '@assets/images/Filled_Checkmark.svg';
import defaultImage from '@assets/images/product-default-img.png';

// Components
import OrderSummary from '@pages/checkout/components/OrderSummary';
import SubscriptionsBookItem from '@global/components/SubscriptionsBookItem';

// Utils
import getOrderPrice from '@global/hooks/getOrderPrice';
import UserService from '@global/Api/keycloak/UserService';

// GraphQL
import { REQUEST_CART } from '@pages/orderConfirmation/queries';

// Types
import { CustomerOrderBiId } from '@src/global/types/types';

const OrderConfirmation: React.FC = () => {
  const orderId = localStorage.getItem('orderId');
  const history = useHistory();

  if (!orderId) {
    history.goBack();
  }

  // Scroll to top of page on render.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Currently logged in user
  const [user, setUser] = useState('');
  const [paybBillingAddress, setBillingAddress] = useState<{
    line1: string;
    line2?: string;
    zip: string;
    city: string;
    state: string;
    tel: string;
  } | null>(null);

  const [paymnetInfo, setPaymnetInfo] = useState<{
    nameOnCard: string;
    cardNumber: string | number;
    expirationDate: string | number;
  } | null>(null);

  const { data, loading } = useQuery(REQUEST_CART, {
    variables: {
      biId: orderId ? orderId : '',
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.getCustomerOrderByBiId?.paybBillingAddress) {
        const address = data.getCustomerOrderByBiId.paybBillingAddress;
        const payment = data.getCustomerOrderByBiId.paybPayment;

        setBillingAddress({
          line1: address.upaLine1,
          line2: address.upaLine2,
          zip: address.upaPostcode,
          city: address.upaCity,
          state: address.gadStateOrProvince,
          tel: data.getCustomerOrderByBiId.wContactPhone,
        });

        if (payment) {
          setPaymnetInfo({
            nameOnCard: payment.ccpaNameOnCard,
            cardNumber: payment.ccpaCardNumber,
            expirationDate: payment.ccpaExpirationDate,
          });
        }
      }
    },
  }) as { data: CustomerOrderBiId; loading: boolean };

  if (UserService.isLoggedIn() && !user) {
    setUser(UserService.getUserEmail());
  }

  const cartContents = data?.getCustomerOrderByBiId?.hiConsistsOf;

  const orderItems = cartContents?.map((item) => {
    return {
      item: item.biiProduct.name,
      price: item.biiProduct.pricPrice['edges'][0].node.amount,
    };
  });

  const currentDate = new Date();
  const datePlusMonth = new Date(
    currentDate.setMonth(currentDate.getMonth() + 1)
  );

  const nextBilldate = format(datePlusMonth, 'MMMM d, yyyy');

  const purchasedItemData = cartContents?.map((item) => {
    return {
      imgUrl: item.biiProduct.imageUrl || defaultImage,
      navEntity: {
        to: 'subscriptions',
        isInAppNav: true,
        label: item.biiProduct.name,
      },
      bottomContent: {
        top: {
          left: 'Next Bill Date',
          right: 'Recurring Monthly Cost',
        },
        bottom: {
          left: nextBilldate,
          right: `${item.biiProduct.pricPrice['edges'][0].node.amount}`,
        },
      },
    };
  });

  let orderPrice = { total: 0, tax: 0 };
  if (data?.getCustomerOrderByBiId?.pricPrice) {
    orderPrice = getOrderPrice(data.getCustomerOrderByBiId.pricPrice?.edges);
  }

  // If navigating away from page, clear the session storage.
  const unlisten = history.listen(() => {
    localStorage.removeItem('orderId');
    unlisten();
  });

  const address = paybBillingAddress && paymnetInfo && (
    <div className="payment-details__column">
      <p className="payment-details__item payment-details__item_bold">
        {paymnetInfo.nameOnCard}
      </p>
      <span className="payment-details__item">{paybBillingAddress.tel}</span>
      <span className="payment-details__item">{paybBillingAddress.line1}</span>
      <span className="payment-details__item">{paybBillingAddress.line2}</span>
      <span className="payment-details__item">
        {paybBillingAddress.city},&nbsp;{paybBillingAddress.zip}&nbsp;
        {paybBillingAddress.state}
      </span>
    </div>
  );

  const payment = paymnetInfo && (
    <div className="payment-details__column">
      <p className="payment-details__item payment-details__item_secondary-color">
        Payment Method
      </p>
      <p className="payment-details__item">{paymnetInfo.cardNumber}</p>
      <p className="payment-details__item">{paymnetInfo.expirationDate}</p>
    </div>
  );

  return orderId ? (
    <main className="order-confirmation">
      <div className="order-confirmation__title-wrapper">
        <div className="order-confirmation__title-wrapper--title">
          <CheckmarkIcon />
          <span>Thanks for your order!</span>
        </div>

        <p className="order-confirmation__title-wrapper--message">
          Confirmation of your order details have been sent to{' '}
          <span>{user}</span>
        </p>
      </div>

      <div className="order-confirmation__container">
        <div className="order-confirmation__steps-container">
          <h3 className="order-confirmation__subtitle">Order details</h3>
          <div className="order-confirmation__vitalsource-link">
            Access your VitalSource subscription
          </div>
          <p className="regularText">
            Your subscription has been purchased for the account{' '}
            <span className="order-confirmation__link">{user}</span>
          </p>
          <div className="order-confirmation__payment-details">
            {address}
            {payment}
          </div>
          <h3 className="order-confirmation__subtitle order-confirmation__subtitle_no-border">
            Subscription Purchased
          </h3>
          <div className="order-confirmation__purchased-items">
            {!loading ? (
              purchasedItemData &&
              purchasedItemData.map((item) => {
                return (
                  <SubscriptionsBookItem
                    key={item.navEntity.label}
                    itemEntity={item}
                    active={true}
                  />
                );
              })
            ) : (
              <div className="spinner" id="spinner"></div>
            )}
          </div>
        </div>

        <div className="order-summary-confirmation">
          <OrderSummary
            orderItems={orderItems}
            orderPrice={orderPrice}
            isSummaryLoading={loading}
          />
        </div>
      </div>
    </main>
  ) : (
    <h4>Redirecting...</h4>
  );
};

export default OrderConfirmation;
