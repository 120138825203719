import { gql } from '@apollo/client';

export const ORDER_BY_CART_ID = gql`
  query getCustomerOrderByBiId($biId: String!) {
    getCustomerOrderByBiId(biId: $biId) {
      biId
      id
      bpStatus {
        code
      }
    }
  }
`;
