export default (
  serverPrices: {
    mapsOn: {
      code?: string;
      __typename?: string;
    };
    node: {
      amount?: number;
      __typename?: string;
    };
  }[]
) => {
  const getPriceByCode = (code: string) => {
    return (
      serverPrices.find((item) => item.mapsOn.code === code)?.node?.amount || 0
    );
  };

  return {
    tax: getPriceByCode('TAX'),
    total: getPriceByCode('TOTAL'),
    price: getPriceByCode('PRICE'),
  };
};
