// Modules
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

// CSS and assets
import './style.scss';
import { ReactComponent as PlusIcon } from '@assets/images/plus_icon.svg';

// Utils
import { US_STATES } from '@src/global/App/constants/location/constants';

type billingInfo = {
  name: string;
  address1: string;
  address2: string;
  zip: string;
  city: string;
  state: string;
  phone: string;
};

interface IProps {
  changeBillingInfo: billingInfo;
  setChangeBillingInfo: React.Dispatch<React.SetStateAction<billingInfo>>;
  handleBillingInfoChange: () => void;
}

const BillingInfoChangeForm: React.FC<IProps> = ({
  handleBillingInfoChange,
  changeBillingInfo,
  setChangeBillingInfo,
}) => {
  const [addressTwoVisibility, setAddressTwoVisibility] = useState(false);

  //React Hook Form
  const {
    register,
    watch,
    formState: { errors: rhfErrors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      billingName: changeBillingInfo.name,
      billingAddress1: changeBillingInfo.address1,
      billingAddress2: changeBillingInfo.address2,
      billingZip: changeBillingInfo.zip,
      billingCity: changeBillingInfo.city,
      billingState: changeBillingInfo.state,
      billingPhone: changeBillingInfo.phone,
    },
  });

  const billingName = watch('billingName');
  const billingAddress1 = watch('billingAddress1');
  const billingAddress2 = watch('billingAddress2');
  const billingZip = watch('billingZip');
  const billingCity = watch('billingCity');
  const billingState = watch('billingState');
  const billingPhone = watch('billingPhone');

  // Phone number formatting
  const normalizePhoneNumber = (value) => {
    const USNum = value
      .replace(/\D/g, '')
      .slice(-10)
      .match(/(\d?)(\d?)(\d?)(\d{0,3})(\d{0,4})/);
    let num = '';
    num += USNum[1] ? `(${USNum[1]}` : '';
    num += USNum[2] ? USNum[2] : '';
    num += USNum[3] ? `${USNum[3]}` : '';
    num += USNum[4] ? `) ${USNum[4]}` : '';
    num += USNum[5] ? `-${USNum[5]}` : '';
    return num || '';
  };

  useEffect(() => {
    setChangeBillingInfo({
      ...changeBillingInfo,
      name: billingName,
      address1: billingAddress1,
      address2: billingAddress2,
      zip: billingZip,
      city: billingCity,
      state: billingState,
      phone: billingPhone,
    });
  }, [
    billingName,
    billingAddress1,
    billingAddress2,
    billingZip,
    billingCity,
    billingState,
    billingPhone,
  ]);

  // Disable save button if input is invalid.
  useEffect(() => {
    const actionBtn = document.querySelector(
      '.modal__action-btn'
    ) as HTMLButtonElement;
    if (!isValid) {
      actionBtn.disabled = true;
    } else actionBtn.disabled = false;
  }, [isValid]);

  return (
    <div className="change-billing-form-container">
      <span>*Required Fields</span>

      <form className="change-billing" onSubmit={handleBillingInfoChange}>
        {/* NAME */}
        <div
          className={
            'change-billing__name ' +
            'active-validation-field' +
            (rhfErrors.billingName ? ' validation-error' : '') +
            (billingName ? ' validation-success' : '')
          }
        >
          <label htmlFor="change-name">*Name on Credit Card:</label>
          <input
            type="text"
            id="change-name"
            name="change-name"
            {...register('billingName', {
              required: 'Invalid name entered.',
              pattern: {
                value: /^[a-zA-Z ']+$/,
                message: 'Please, remove digits or special symbols from Name.',
              },
            })}
          />
          {rhfErrors.billingName && (
            <span className="error">{rhfErrors.billingName.message}</span>
          )}
        </div>

        {/* ADDRESS */}
        <div className="change-billing__address">
          <div
            className={
              'active-validation-field' +
              (rhfErrors.billingAddress1 ? ' validation-error' : '') +
              (billingAddress1 ? ' validation-success' : '')
            }
          >
            <label htmlFor="change-address-1">*Address Line 1</label>
            <input
              type="text"
              id="change-address-1"
              name="change-address-1"
              {...register('billingAddress1', {
                required: 'Address Line 1 is required.',
                minLength: { value: 4, message: 'Minimum address length is 4' },
              })}
            />
            {rhfErrors.billingAddress1 && (
              <span className="error">{rhfErrors.billingAddress1.message}</span>
            )}
          </div>
          {addressTwoVisibility && (
            <div
              className={
                'active-validation-field' +
                (rhfErrors.billingAddress2 ? ' validation-error' : '') +
                (billingAddress2 ? ' validation-success' : '')
              }
            >
              <label htmlFor="change-address-2">Address Line 2</label>
              <input
                type="text"
                id="change-address-2"
                name="change-address-2"
                {...register('billingAddress2', {
                  minLength: {
                    value: 4,
                    message: 'Minimum address length is 4',
                  },
                })}
              />
              {rhfErrors.billingAddress2 && (
                <span className="error">
                  {rhfErrors.billingAddress2.message}
                </span>
              )}
            </div>
          )}
        </div>

        {!addressTwoVisibility && (
          <button
            className="change-billing__add-address-btn"
            type="button"
            onClick={() => setAddressTwoVisibility((prev) => !prev)}
          >
            <PlusIcon className="plus-icon" />
            Add Address Line 2
          </button>
        )}

        {/* ZIP CODE */}
        <div
          className={
            'change-billing__zip ' +
            'active-validation-field' +
            (rhfErrors.billingZip ? ' validation-error' : '') +
            (billingZip ? ' validation-success' : '')
          }
        >
          <label htmlFor="change-zip">*ZIP Code:</label>
          <input
            type="text"
            id="change-zip"
            name="change-zip"
            {...register('billingZip', {
              required: 'Zip Code is required.',
            })}
          />
          {rhfErrors.billingZip && (
            <span className="error">{rhfErrors.billingZip.message}</span>
          )}
        </div>

        {/* CITY */}
        <div
          className={
            'change-billing__city ' +
            'active-validation-field' +
            (rhfErrors.billingCity ? ' validation-error' : '') +
            (billingCity ? ' validation-success' : '')
          }
        >
          <label htmlFor="change-city">*City:</label>
          <input
            type="text"
            id="change-city"
            name="change-city"
            {...register('billingCity', {
              required: 'Please, specify your city.',
            })}
          />
          {rhfErrors.billingCity && (
            <span className="error">{rhfErrors.billingCity.message}</span>
          )}
        </div>

        {/* STATE */}
        <div
          className={
            'change-billing__state ' +
            'active-validation-field' +
            (rhfErrors.billingState ? ' validation-error' : '') +
            (billingState ? ' validation-success' : '')
          }
        >
          <label htmlFor="change-state">*State:</label>
          <div>
            <select
              id="change-state"
              name="change-state"
              {...register('billingState', {
                required: 'Please, specify your State.',
              })}
            >
              <option hidden></option>
              {US_STATES.map((state) => {
                return (
                  <option key={state} value={state}>
                    {state}
                  </option>
                );
              })}
            </select>
            {rhfErrors.billingState && (
              <span className="error">{rhfErrors.billingState.message}</span>
            )}
          </div>
        </div>

        {/* PHONE NUMBER */}
        <div
          className={
            'change-billing__phone ' +
            'active-validation-field' +
            (rhfErrors.billingPhone ? ' validation-error' : '') +
            (billingPhone ? ' validation-success' : '')
          }
        >
          <label htmlFor="change-phone">*Phone Number:</label>
          <input
            type="tel"
            placeholder="(XXX) XXX-XXXX"
            id="change-phone"
            name="change-phone"
            {...register('billingPhone', {
              required: 'Your phone is required',
              pattern: {
                value: /\([0-9]{3}\) [0-9]{3}[-][0-9]{4}$/,
                message:
                  'Please, specify your phone correctly like (XXX) XXX-XXXX',
              },
              onChange: (e) => {
                const { value, selectionStart } = e.target;
                e.target.value = normalizePhoneNumber(value);
                if (e.target.selectionEnd > 0 && e.target.selectionEnd < 4) {
                  e.target.selectionEnd = selectionStart + 1;
                }
              },
            })}
            maxLength={14}
          />
          {rhfErrors.billingPhone && (
            <span className="error">{rhfErrors.billingPhone.message}</span>
          )}
        </div>
      </form>
    </div>
  );
};

export default BillingInfoChangeForm;
