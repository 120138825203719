import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import UserService from '@global/Api/keycloak/UserService';
import { GRAPHQL_URI } from '@global/App/constants/constants';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      console.error(`GraphQL error ${message}`);
    });
  }
});

const authLink = setContext((_, { headers }) => {
  const token = UserService.getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const link = from([errorLink, new HttpLink({ uri: GRAPHQL_URI })]);

const typePolicies = {
  ViaxCustomerOrder: {
    keyFields: ['biId'],
  },
  CustomerOrder: {
    keyFields: ['biId'],
  },
  CustomerOrderItem: {
    keyFields: ['biiId'],
  },
  Product: {
    keyFields: ['sku'],
  },
  WileyBookAuthor: {
    keyFields: ['uid'],
  },
};

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({
    typePolicies,
  }),
});
