import React, { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '@src/pages/checkout/components/SecurePayment/form';

// Load payment stripe
const stripePromise = loadStripe(
  'pk_test_51JyZudGNIybthUSGnnY9C5qAgadOszJi2FOXbOJoAdWmwzNE0XUmc1pA0jaYBCBkPRefJT1pcHHkw3reYxzISVaI00f2cuqRvz'
);

interface IProps {
  errors: {
    stepOne: {
      loginInfo: boolean;
      createEmail: boolean;
    };
    stepTwo: {
      nameCC: boolean;
    };
    stepThree: {
      invalidDetails: boolean;
    };
  };
  setErrors: React.Dispatch<
    React.SetStateAction<{
      stepOne: {
        loginInfo: boolean;
        createEmail: boolean;
      };
      stepTwo: {
        nameCC: boolean;
      };
      stepThree: {
        invalidDetails: boolean;
      };
    }>
  >;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SecurePayment: React.FC<IProps> = ({
  errors,
  setErrors,
  isLoading,
  setIsLoading,
}) => {
  // Clear errors on component render
  useEffect(() => {
    setErrors((prev) => {
      return {
        ...prev,
        stepThree: {
          invalidDetails: false,
        },
      };
    });
  }, []);

  return (
    <>
      <Elements stripe={stripePromise}>
        {/* {errors.stepThree.invalidDetails && (
          <StyledError>
            Unable to process your payment. Please try again.
          </StyledError>
        )} */}
        <PaymentForm
          errors={errors}
          setErrors={setErrors}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Elements>
    </>
  );
};

export default SecurePayment;
