class request {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static post: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static get: any;
}

export const sendAuthRequest = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const res = await request.post(email, password);

  sessionStorage.setItem('accessToken', res.accessToken);
  localStorage.setItem('refreshToken', res.refreshToken);

  return res;
};

export const sendRefreshTokenRequest = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const res = await request.post(
    `/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`,
    {},
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic dGVzdDpzZWNyZXQ=',
      },
    }
  );

  if (!res) {
    // TODO make sure that there is no response if refresh token is invalid
    sessionStorage.clear();
    localStorage.clear();
    return;
  }

  // todo if refresh token valid - update refresh token and access token
  return res;
};

export const fetchUser = async () => {
  return await request.get('/session/user');
};

export const fetchAccounts = async () => {
  return await request.get('/api/v1/account');
};
