// Modules
import React from 'react';
import { Link } from 'react-router-dom';

// CSS and assets
import './style.scss';

interface IProps {
  panelInfo: {
    imageURL: string;
    title: string;
    contentText: string;
    learnMoreURL?: string;
  }[];
}
const FourPanel: React.FC<IProps> = ({ panelInfo }) => {
  return (
    <div className="four-panel">
      {panelInfo.map((el) => (
        <div key={el.title} className="four-panel__panel-wrapper">
          <div className="four-panel__img-container">
            <img
              className="four-panel__panel-img"
              src={el.imageURL}
              alt={el.title}
            />
          </div>
          <div className="four-panel__content-wrapper">
            <span className="four-panel__title">{el.title}</span>
            <span className="four-panel__content">{el.contentText}</span>
            {el.learnMoreURL && (
              <Link className="four-panel__learn-more" to={el.learnMoreURL}>
                Learn more
              </Link>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FourPanel;
