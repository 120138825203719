// Modules
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// CSS and assets
import './style.scss';

// Components
import ButtonCTA from '@global/components/UI Elements/ButtonCTA';

interface IProps {
  customClass?: string;
  isModalVisible?: boolean;
  setIsModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  modalData?: {
    title?: string;
    content?: string | React.ReactNode;
    actionBtnText?: string;
    action?: () => void;
    backBtnText?: string;
  };
}

const Modal: React.FC<IProps> = ({
  customClass,
  isModalVisible,
  setIsModalVisible,
  modalData: { title, content, actionBtnText, action, backBtnText },
}) => {
  const [verticalOffset, setVerticalOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const closeModal = () => {
    setIsModalVisible(false);

    // The below code is bugged. The idea is to refocus on trigger button after modal is closed.
    // Currently, something is stealing that focus on the body.
    // IDEA: pass id of button on click, then docus on that id.

    // const triggerButton = refModal.current
    //   .closest('.pdp')
    //   .querySelector('#add-to-cart-btn') as HTMLButtonElement;

    // triggerButton.focus();
  };

  // Store references to first/last focusable elements.
  const refModal = useRef<HTMLDivElement | null>(null);
  const refFirstFocusable = useRef<HTMLElement | null>(null);
  const refLastFocusable = useRef<HTMLElement | null>(null);

  // When DOM is mounted and modal is open, initialize ref elements.
  useEffect(() => {
    if (isModalVisible) {
      const focusableElements = Array.from<HTMLElement>(
        refModal.current?.querySelectorAll('[tabindex]') ?? []
      );

      refFirstFocusable.current = focusableElements[0];
      refLastFocusable.current =
        focusableElements[focusableElements.length - 1];

      // Auto focus on first available element.
      focusableElements[0].focus();
    }
  }, [isModalVisible]);

  // When modal is open, prevent page scroll.
  useEffect(() => {
    if (isModalVisible) {
      setVerticalOffset(window.scrollY);
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add('noscroll');
    } else {
      document.body.style.removeProperty('top');
      document.body.classList.remove('noscroll');
      window.scrollBy(0, verticalOffset);
    }
  }, [isModalVisible]);

  // If navigating away from page, remove scroll limitation.
  const unlisten = history.listen(() => {
    document.body.classList.remove('noscroll');
    unlisten();
  });

  // Accessible navigation & lock focus inside modal.
  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (
      e.key === 'Tab' &&
      !e.shiftKey &&
      document.activeElement === refLastFocusable.current
    ) {
      e.preventDefault();
      refFirstFocusable.current?.focus();
    }

    if (
      e.key === 'Tab' &&
      e.shiftKey &&
      document.activeElement === refFirstFocusable.current
    ) {
      e.preventDefault();
      refLastFocusable.current?.focus();
    }

    if (e.key === 'Escape') {
      closeModal();
    }
  }, []);

  return (
    isModalVisible && (
      <>
        <div
          className={customClass ? 'modal ' + customClass : 'modal'}
          ref={refModal}
          onKeyDown={handleKeyDown}
        >
          <button
            className="modal__close-btn"
            onClick={closeModal}
            tabIndex={0}
          ></button>

          <span className="modal__title">{title}</span>

          <div className="modal__content">{content}</div>

          <ButtonCTA
            variant="primary"
            className="modal__action-btn"
            onClick={async () => {
              setIsLoading(true);
              await action();
              setIsLoading(false);
            }}
            tabIndex={0}
            disabled={isLoading}
          >
            {isLoading ? <div className="spinner"></div> : actionBtnText}
          </ButtonCTA>

          {/* Wrapping the button in a span is an iOS Safari fix for invisible button text on some devices */}
          <span style={{ textAlign: 'center' }}>
            <button
              className="modal__back-btn"
              onClick={closeModal}
              tabIndex={0}
            >
              {backBtnText}
            </button>
          </span>
        </div>

        <div className="overlay" onClick={closeModal}></div>
      </>
    )
  );
};

export default Modal;
