import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// CSS and assets
import '@pages/register/style.scss';

// Utils
import { useForm } from 'react-hook-form';
import ButtonCTA from '@global/components/UI Elements/ButtonCTA';
import { COUNTRIES } from '@global/App/constants/location/constants';
import ButtonShow from '@global/components/ActiveForm/Elements/ButtonShow';

const CreateAccount: React.FC = () => {
  //React Hook Form
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    getFieldState,
    clearErrors,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const registerEmail = watch('registerEmail');
  const registerEmailConfirmation = watch('registerEmailConfirmation');
  const registerPassword = watch('registerPassword');
  const registerPasswordConfirmation = watch('registerPasswordConfirmation');
  const registerCountry = watch('registerCountry');

  // Scroll to top of page on render.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formSubmitHandler = async () => {
    return true;
  };

  const regLetter = '(?=(.*[a-z]))';
  const regCapital = '(?=(.*[A-Z]))';
  const regSpecial = '(?=(.*[!@#$%^&]))';
  const regDigit = '(?=(.*[0-9]))';
  const regPass = new RegExp(
    `${regLetter}${regSpecial}${regDigit}|${regLetter}${regCapital}${regDigit}|${regLetter}${regCapital}${regSpecial}|${regCapital}${regSpecial}${regDigit}`
  );

  return (
    <main className="page-create-account">
      <div className="page-title underlinedBlock">
        <div className="inner-wrapper">
          <h1>Create Account</h1>
        </div>
      </div>
      <div className="page-body">
        <div className="inner-wrapper">
          <div className="create-account">
            <Link className="underlined-link" to={`/login`}>
              Log in to existing account
            </Link>
          </div>
          <form
            className="active-form"
            id="loginForm"
            onSubmit={handleSubmit(formSubmitHandler)}
          >
            <div className="form-description">*Required Fields</div>

            <div className="form-row double">
              {/* First Name */}
              <div
                className={
                  'active-validation-field' +
                  ' first-name-field' +
                  (errors.firstName ? ' validation-error' : '') +
                  (firstName ? ' validation-success' : '')
                }
              >
                <label htmlFor="firstName">*First Name:</label>
                <input
                  type="text"
                  {...register('firstName', {
                    required: 'Your Name is required.',
                    pattern: {
                      value: /^[a-zA-Z- ']+$/,
                      message:
                        'Name must contain only letters, apostrophe or dash',
                    },
                  })}
                />
                {errors.firstName && (
                  <span className="error">{errors.firstName.message}</span>
                )}
              </div>
              {/* Last Name */}
              <div
                className={
                  'active-validation-field' +
                  ' last-name-field' +
                  (errors.lastName ? ' validation-error' : '') +
                  (lastName ? ' validation-success' : '')
                }
              >
                <label htmlFor="lastName">*Last Name:</label>
                <input
                  type="text"
                  {...register('lastName', {
                    required: 'Your Last Name is required.',
                    pattern: {
                      value: /[A-Za-z' -]$/,
                      message:
                        'Last Name must contain only letters, apostrophe or dash',
                    },
                  })}
                />
                {errors.lastName && (
                  <span className="error">{errors.lastName.message}</span>
                )}
              </div>
            </div>

            <div className="form-row double">
              {/* E-MAIL */}
              <div
                className={
                  'active-validation-field' +
                  ' email-field' +
                  (errors.registerEmail ? ' validation-error' : '') +
                  (registerEmail ? ' validation-success' : '')
                }
              >
                <label htmlFor="registerEmail">*Email Address:</label>
                <input
                  type="text"
                  {...register('registerEmail', {
                    required: 'E-Mail required for account registration.',
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please, type E-Mail correctly',
                    },
                    onChange: (e) => {
                      if (registerEmailConfirmation === e.target.value)
                        clearErrors('registerEmailConfirmation');
                    },
                  })}
                />
                {errors.registerEmail && (
                  <span className="error">{errors.registerEmail.message}</span>
                )}
              </div>
              {/* E-MAIL CONFIRMATION */}
              <div
                className={
                  'active-validation-field' +
                  ' email-confirmation-field' +
                  (getFieldState('registerEmailConfirmation').isTouched &&
                  registerEmailConfirmation !== registerEmail
                    ? ' validation-error'
                    : '') +
                  (errors.registerEmailConfirmation
                    ? ' validation-error'
                    : '') +
                  (registerEmailConfirmation ? ' validation-success' : '')
                }
              >
                <label htmlFor="registerEmailConfirmation">
                  *Confirm Email Address:
                </label>
                <input
                  type="text"
                  {...register('registerEmailConfirmation', {
                    required: 'Confirm E-Mail',
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please, type E-Mail correctly',
                    },
                    validate: {
                      matchesEmail: (value) => {
                        return (
                          registerEmail === value || 'E-mail should match!'
                        );
                      },
                    },
                  })}
                />
                {errors.registerEmailConfirmation && (
                  <span className="error">
                    {errors.registerEmailConfirmation.message}
                  </span>
                )}
              </div>
            </div>

            <div className="form-row double">
              {/* PASSWORD */}
              <div
                className={
                  'active-validation-field' +
                  ' password-field' +
                  ' password-show-hide' +
                  (errors.registerPassword ? ' validation-error' : '') +
                  (registerPassword ? ' validation-success' : '')
                }
              >
                <label htmlFor="registerPassword">*Password:</label>
                <div className="show-hide-wrapper">
                  <input
                    type="password"
                    {...register('registerPassword', {
                      required: 'Password required for registration.',
                      pattern: {
                        value: regPass,
                        message: "Password doesn't meet all the requirements",
                      },
                      minLength: {
                        value: 10,
                        message: "Password doesn't meet all the requirements",
                      },
                      onChange: (e) => {
                        if (registerPasswordConfirmation === e.target.value)
                          clearErrors('registerPasswordConfirmation');
                      },
                    })}
                  />
                  <ButtonShow />
                </div>
                {errors.registerPassword && (
                  <span className="error">
                    {errors.registerPassword.message}
                  </span>
                )}
              </div>
              {/* PASSWORD CONFIRMATION */}

              <div
                className={
                  'active-validation-field' +
                  ' password-confirmation-field' +
                  (registerPasswordConfirmation !== registerPassword
                    ? ' validation-error'
                    : '') +
                  (errors.registerPasswordConfirmation
                    ? ' validation-error'
                    : '') +
                  (registerPasswordConfirmation ? ' validation-success' : '')
                }
              >
                <label htmlFor="registerPasswordConfirmation">
                  *Confirm Password:
                </label>
                <div className="show-hide-wrapper">
                  <input
                    type="password"
                    {...register('registerPasswordConfirmation', {
                      required: 'Confirm password',
                      validate: {
                        matchesPreviousPassword: (value) => {
                          return (
                            registerPassword === value ||
                            'Passwords should match!'
                          );
                        },
                      },
                    })}
                  />
                  <ButtonShow />
                </div>
                {errors.registerPasswordConfirmation && (
                  <span className="error">
                    {errors.registerPasswordConfirmation.message}
                  </span>
                )}
              </div>
              <div className="row-description">
                Password Requirements:
                <ul className="validation-requirements">
                  <li
                    className={
                      registerPassword && registerPassword.length > 9
                        ? 'match'
                        : ''
                    }
                  >
                    At least 10 characters
                  </li>
                  <li
                    className={
                      RegExp(regPass).test(registerPassword) ? 'match' : ''
                    }
                  >
                    At least 3 of the following categories:
                  </li>
                  <li className="sub-validation">
                    <ul>
                      <li
                        className={
                          RegExp(regCapital).test(registerPassword)
                            ? 'match'
                            : ''
                        }
                      >
                        Uppercase letter [A-Z]
                      </li>
                      <li
                        className={
                          registerPassword &&
                          RegExp(regLetter).test(registerPassword)
                            ? 'match'
                            : ''
                        }
                      >
                        Lowercase letter [a-z]
                      </li>
                      <li
                        className={
                          RegExp(regDigit).test(registerPassword) ? 'match' : ''
                        }
                      >
                        Number [0-9]
                      </li>
                      <li
                        className={
                          RegExp(regSpecial).test(registerPassword)
                            ? 'match'
                            : ''
                        }
                      >
                        Special characters [!@#$%^&]
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div className="form-row double">
              {/* COUNTRY */}
              <div
                className={
                  'active-validation-field' +
                  ' country-select-field' +
                  (errors.registerCountry ? ' validation-error' : '') +
                  (registerCountry ? ' validation-success' : '')
                }
              >
                <label htmlFor="registerCountry">*Country:</label>
                <div className="select-wrapper">
                  <select
                    id="registerCountry"
                    {...register('registerCountry', {
                      required: 'Please, specify your Country.',
                    })}
                  >
                    <option value="" disabled selected={true}>
                      Select Country
                    </option>
                    {COUNTRIES.map((state) => {
                      return (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      );
                    })}
                  </select>
                  {errors.registerCountry && (
                    <span className="error">
                      {errors.registerCountry.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="checkbox-wrapper">
                <input
                  id="offers"
                  type="checkbox"
                  value="Yes"
                  {...register('offers')}
                />
                <label htmlFor="offers">
                  I’d like to receive updates and offers from Wiley.
                </label>
              </div>
            </div>

            <div className="submit-block">
              <ButtonCTA
                variant="primary"
                className="extra-class submit-btn billing-info__submit-btn"
                type="submit"
                id="continue-to-payment-btn"
                disabled={!isValid}
              >
                Register
              </ButtonCTA>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default CreateAccount;
