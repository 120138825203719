import React, { useState } from 'react';
import './style.scss';

// Types
import { PurchasedSubscription, ViaxCustomerOrder } from '@global/types/types';

//queries
import { NEW_CART, UPDATE_CART, GET_SUBSCRIPTIONS } from './queries';
import * as Constants from '@global/App/constants/constants';
import { useMutation, useQuery } from '@apollo/client';

import i18n from 'i18next';
import ButtonCTA from '@global/components/UI Elements/ButtonCTA';
import UserService from '@global/Api/keycloak/UserService';
import { useHistory } from 'react-router-dom';
import { prefixPath } from '@global/localization/services/services';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  forceActive?: boolean;
}

const ButtonCheckout: React.FC<IProps> = ({ text, forceActive }) => {
  const [isLoading, setIsLoading] = useState(false);
  const cartId = localStorage.getItem(Constants.CART_ID_ATTRIBUTE);
  const [sku, setSku] = useState(Constants.SKU[i18n.language]);
  let subscriptions = null;
  const history = useHistory();
  const [mutateNewCart] = useMutation(NEW_CART);
  const [mutateUpdateCart] = useMutation(UPDATE_CART);

  const checkUserSubscriptions = () => {
    const {
      data: {
        filterViaxSubscriptionContractBy: { page: subscriptions = [] } = {},
      } = {},
    } = useQuery(GET_SUBSCRIPTIONS, {
      variables: {
        filter: `bpStatus=Active AND biParties.paId=\"${UserService.getUserUid()}\"`,
        orderBy: { field: 'biId', direction: 'DESC' },
      },
      fetchPolicy: 'network-only',
    }) as {
      data: {
        filterViaxSubscriptionContractBy: {
          page: PurchasedSubscription[] | [];
        };
      };
      loading: boolean;
    };
    return subscriptions;
  };

  const createUpdateCart = async (cartId) => {
    if (cartId) {
      const updatedCart = [
        {
          typeName: 'ViaxSubscriptionContractItem',
          biiProduct: { sku: sku },
        },
      ];
      await mutateUpdateCart({
        variables: {
          biId: cartId,
          contents: updatedCart,
        },
        onCompleted: () => {
          setIsLoading(false);
        },
      });
    } else {
      const { data } = (await mutateNewCart({
        variables: {
          skuId: sku,
        },
        onCompleted: () => {
          setIsLoading(false);
        },
      })) as { data: ViaxCustomerOrder };

      localStorage.setItem(
        Constants.CART_ID_ATTRIBUTE,
        data.createViaxCustomerOrder.biId
      );
    }
  };

  i18n.on('languageChanged', () => {
    setSku(Constants.SKU[i18n.language]);
  });

  if (UserService.isLoggedIn()) {
    subscriptions = forceActive ? null : checkUserSubscriptions();
  }

  const addToCartHandler = async () => {
    setIsLoading(true);
    await createUpdateCart(cartId);
    history.push(prefixPath(Constants.Pages.CHECKOUT, i18n.language));
  };

  return (
    <ButtonCTA
      variant="primary"
      onClick={subscriptions ? null : addToCartHandler}
      disabled={isLoading || subscriptions}
      className="add-to-cart-btn"
    >
      {isLoading ? <div className="spinner"></div> : text}
    </ButtonCTA>
  );
};

export default ButtonCheckout;
