import { gql } from '@apollo/client';

export const REQUEST_CART = gql`
  query getCustomerOrderByBiId($biId: String!) {
    getCustomerOrderByBiId(biId: $biId) {
      biId
      id
      biCreatedAt
      # order items
      paybPayment {
        ... on StripeCustomerPeriodicCreditCardPayment {
          ccpaNameOnCard
          ccpaCardNumber
          ccpaExpirationDate
        }
      }
      wContactPhone
      pricPrice(_mapsOn: [{ code: "TAX" }, { code: "TOTAL" }]) {
        edges {
          mapsOn {
            code
          }
          node {
            amount
          }
        }
      }
      wContactPhone
      wTOSAccepted
      wMailingListSubscriptionAccepted
      ... on ViaxSubscriptionContract {
        subsValidPeriod {
          start
          end
        }
        paybNextBillDate
      }
      paybBillingAddress {
        uid
        id
        gadCountry {
          name
          uid
          numericCode
        }
        gadStateOrProvince
        upaLine1
        upaLine2
        upaPostcode
        upaCity
      }
      hiConsistsOf {
        # primary key; order item number
        biiId
        # sorting key
        biiSequentialId
        # product data
        biiProduct {
          uid
          sku
          name
          imageUrl
          wBookPublishDate
          wBookAuthors {
            uid
            wName
          }
          pricPrice(_mapsOn: { code: "PRICE" }) {
            edges {
              mapsOn {
                code
              }
              node {
                amount
              }
            }
          }
        }
      }
    }
  }
`;
