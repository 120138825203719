interface billingInfoInput {
  name: string;
  tel: string;
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zip: string;
  uid?: string;
}

interface billingInfoResult {
  paybBillingAddress: {
    typeName: string;
    upaLine1: string;
    upaLine2: string;
    upaCity: string;
    gadStateOrProvince: string;
    upaPostcode: string;
    gadCountry: {
      iso2Code: string;
    };
    uid?: string;
  };
  wContactPhone: string;
  wTOSAccepted?: boolean;
  wMailingListSubscriptionAccepted?: boolean;
  paybPayment: {
    typeName: string;
    _stripeCustomerPeriodicCreditCardPayment: {
      ccpaNameOnCard: string;
    };
  };
}

export function mapToServer(billingInfo: billingInfoInput): billingInfoResult {
  const result: billingInfoResult = {
    paybBillingAddress: {
      typeName: 'ViaxUrbanPropertyAddress',
      gadCountry: {
        iso2Code: 'US',
      },
      gadStateOrProvince: billingInfo.state,
      upaLine1: billingInfo.addressOne,
      upaLine2: billingInfo.addressTwo,
      upaPostcode: billingInfo.zip,
      upaCity: billingInfo.city,
    },
    paybPayment: {
      typeName: 'StripeCustomerPeriodicCreditCardPayment',
      _stripeCustomerPeriodicCreditCardPayment: {
        ccpaNameOnCard: billingInfo.name,
      },
    },
    wContactPhone: billingInfo.tel,
  };

  if (billingInfo.uid) {
    result.paybBillingAddress.uid = billingInfo.uid;
  }

  return result;
}
