// Modules
import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import i18n from 'i18next';

// CSS and asets
import '@global/App/style.scss';

// Components
import Header from '@global/components/Header/index';
import Footer from '@global/components/Footer/index';
import NewsSignUpPanel from '@global/components/NewsSignUpPanel';
import Page404 from '@src/pages/404';

// Utils
import { mainRoutes } from '@global/router';
import * as Constants from '@global/App/constants/constants';
import UserService from '../Api/keycloak/UserService';
import { supportedLngs } from '@global/localization/i18n';
import {
  langExistsInPath,
  getCurrentPath,
  prefixPath,
} from '@global/localization/services/services';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header isLogged={UserService.isLoggedIn()} />

        <div className="page-wrapper">
          <Switch>
            {/* Redirect to main PDP page when root is hit */}
            <Route
              exact
              path={prefixPath(Constants.Pages.ROOT, i18n.language)}
              component={() => {
                return (
                  <Redirect
                    to={{
                      pathname: prefixPath(Constants.Pages.PDP1, i18n.language),
                    }}
                  />
                );
              }}
            />

            {mainRoutes.map(({ path, component }) => (
              <Route exact key={path} path={path}>
                {component}
                {path !== `/:locale${Constants.Pages.CHECKOUT}/` && (
                  <NewsSignUpPanel />
                )}
              </Route>
            ))}

            {/* 404 page not found */}
            <Route
              component={() => {
                if (langExistsInPath(supportedLngs)) {
                  return <Page404 />;
                }

                return (
                  <Redirect
                    to={{
                      pathname: `/${i18n.language}${getCurrentPath()}`,
                    }}
                  />
                );
              }}
            />
          </Switch>
        </div>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
