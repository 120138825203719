// Modules
import React from 'react';

// Components
import Checkout from '@src/pages/checkout';
import CheckoutConfirmation from '@pages/orderConfirmation';
import Subscription from '@src/pages/SubscriptionDetails';
import Pdp1 from '@src/pages/productPages/pdp1';
import Pdp2 from '@src/pages/productPages/pdp2';
import Pdp3 from '@src/pages/productPages/pdp3';
import LoginPage from '@pages/login';
import CreateAccount from '@pages/register';

// Utils
import * as Constants from '@global/App/constants/constants';
import { localizeRoutes } from '@global/localization/services/services';
import PDPBasic from '@pages/productPages/basic';

export const mainRoutes: Array<{
  path: string;
  component?: unknown;
  localize?: boolean;
}> = localizeRoutes([
  { path: Constants.Pages.PDP_BASIC, component: <PDPBasic /> },
  { path: Constants.Pages.PDP1, component: <Pdp1 /> },
  { path: Constants.Pages.PDP2, component: <Pdp2 /> },
  { path: Constants.Pages.PDP3, component: <Pdp3 /> },
  { path: Constants.Pages.CHECKOUT, component: <Checkout /> },
  {
    path: Constants.Pages.ORDER_CONFIRMATION,
    component: <CheckoutConfirmation />,
  },
  { path: Constants.Pages.LOGIN, component: <LoginPage /> },
  { path: Constants.Pages.REGISTER, component: <CreateAccount /> },
  { path: Constants.Pages.SUBSCRIPTION_DETAILS, component: <Subscription /> },
]);
