// Modules
import React from 'react';

// CSS and assets
import './style.scss';
import heroImg2 from '@assets/images/Hero_Img_2.png';

// Components
import HeroImage from '../components/HeroImage';
import ButtonCheckout from '@global/components/UI Elements/ButtonCheckout';

//Layout
const PDPBasic: React.FC = () => {
  return (
    <div className="pdp-basic">
      <HeroImage size="lg" imageURL={heroImg2} contentOffset>
        <h3>PDP Test Page</h3>
        <p>
          Introducing Wiley Chemistry on Demand, Wiley&apos;s affordable and
          accessible eTextbook subscription — featuring a world-class collection
          of content from the leading publisher in chemistry. From undergraduate
          studies to the Ph.D. level and beyond, Wiley Chemistry on Demand has
          you covered.
        </p>
      </HeroImage>
      <div className="text-panel-wrapper">
        <div className="text-panel">
          <p>
            <br />
          </p>
          <ButtonCheckout text={'Checkout'} />
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};

export default PDPBasic;
