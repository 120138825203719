// Modules
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'i18next';

// Css and assets
import './style.scss';
import { ReactComponent as PlusIcon } from '@assets/images/plus_icon.svg';

// Components
import OrderSummaryItem from '@src/pages/checkout/components/OrderSummary/item';
import ButtonCTA from '@src/global/components/UI Elements/ButtonCTA';

// Utils
import UserService from '@global/Api/keycloak/UserService';
import * as Constants from '@global/App/constants/constants';
import { matchCurrentLocation } from '@src/global/utils';
import { prefixPath } from '@global/localization/services/services';
import { Keycloak } from '@global/App/constants/constants';

interface IProps {
  orderItems?: {
    item: string;
    price: number;
  }[];
  stepOne?: boolean;
  stepTwo?: boolean;
  stepThree?: boolean;
  agreementCheck?: boolean;
  checkoutValid?: boolean;
  orderPrice?: {
    total: number;
    tax: number;
  };
  isSummaryLoading?: boolean;
  isLoading?: boolean;
  promoCode?: string;
  setPromoCode?: React.Dispatch<React.SetStateAction<string>>;
  validPromo?: null | React.ReactNode;
  setValidPromo?: React.Dispatch<React.SetStateAction<null | React.ReactNode>>;
  promoError?: boolean;
  setPromoError?: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderSummary: React.FC<IProps> = ({
  orderItems,
  stepOne,
  stepTwo,
  stepThree,
  agreementCheck,
  checkoutValid,
  orderPrice = {
    total: 0,
    tax: 0,
  },
  isSummaryLoading,
  isLoading = false,
  promoCode,
  setPromoCode,
  validPromo,
  setValidPromo,
  promoError,
  setPromoError,
}) => {
  const [viewSummary, setViewSummary] = useState(false);
  const [promoFieldVisibility, setPromoFieldVisibility] = useState(false);

  const history = useHistory();

  // Clicking this button triggers event on the original button in step 2 form
  const continueToPaymentHandler = () => {
    const paymentBtn = document.getElementById('continue-to-payment-btn');
    paymentBtn.click();
  };

  // Clicking this button triggers event on the original button in step 3 form
  const submitOrderHandler = () => {
    const submitBtn = document.getElementById('submit');
    submitBtn.click();
  };

  const handleApplyPromoCode = (e: React.FormEvent) => {
    e.preventDefault();
    // Function to be used to handle application of promo code
    // Note: Temporary functionality. To be integrated with (or be replaced by) API in future.
    let discountedPrice: null | { price: string; discount: number } = null;

    if (promoCode === '') {
      setPromoError(true);
    }

    if (promoCode) {
      if (promoCode === 'FREE1MONTH') {
        discountedPrice = {
          price: orderPrice.total.toFixed(2),
          discount: 100,
        };
      } else if (promoCode === 'FIRSTMONTH70') {
        discountedPrice = {
          price: orderPrice.total.toFixed(2),
          discount: 70,
        };
      } else {
        setPromoError(true);
      }

      if (discountedPrice) {
        setValidPromo(
          <>
            <span className="order-summary__code-applied--left">
              First Month
            </span>
            <div className="order-summary__code-applied--right">
              {discountedPrice.discount === 100 ? (
                <span className="order-summary__code-applied--discount-price">
                  FREE
                </span>
              ) : (
                <div>
                  <span className="order-summary__code-applied--original-price">
                    ${discountedPrice.price}
                  </span>
                  <span className="order-summary__code-applied--discount-price">{`$${(
                    parseFloat(discountedPrice.price) *
                    ((100 - discountedPrice.discount) / 100)
                  ).toFixed(2)}`}</span>
                </div>
              )}
            </div>
            <button
              className="order-summary__code-applied--cancel-btn"
              onClick={() => {
                setPromoCode('');
                setValidPromo(null);
              }}
            >
              <PlusIcon className="plus-icon" />
            </button>
          </>
        );
        setPromoError(false);
        setPromoFieldVisibility(false);
      }
    }
  };

  const isCartEmpty = orderItems && !orderItems.length;

  return (
    <div
      className={
        !isCartEmpty ? 'order-summary' : 'order-summary order-summary-empty'
      }
    >
      <div className="order-summary__wrapper">
        <h2>Order Summary</h2>
        <div
          className={
            viewSummary
              ? 'order-summary__content'
              : 'order-summary__content--hidden'
          }
        >
          {isSummaryLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            <>
              {/* LIST OF ITEMS BEING PURCHASED */}
              {!isCartEmpty &&
                orderItems?.map((item) => {
                  return <OrderSummaryItem key={item.item} orderItem={item} />;
                })}
              {!isCartEmpty ? (
                <>
                  {/* TAXES */}
                  <div className="order-summary__item-tax">
                    <span className="order-summary__item-tax--left">Taxes</span>
                    {matchCurrentLocation(Constants.Pages.CART) && (
                      <span className="order-summary__item-tax--right">
                        Calculated in Checkout
                      </span>
                    )}
                    {stepOne ||
                      (stepTwo && (
                        <span className="order-summary__item-tax--right">
                          Calculated in Billing Information
                        </span>
                      ))}
                    {stepThree && (
                      <span className="order-summary__item-tax--right-bold">
                        ${orderPrice.tax.toFixed(2)}
                      </span>
                    )}
                    {matchCurrentLocation(
                      Constants.Pages.ORDER_CONFIRMATION
                    ) && (
                      <span className="order-summary__item-tax--right-bold">
                        ${orderPrice.tax.toFixed(2)}
                      </span>
                    )}
                  </div>

                  {/* DISCOUNT COUPON */}
                  {matchCurrentLocation(Constants.Pages.CHECKOUT) &&
                    validPromo && (
                      <div className="order-summary__code-applied">
                        {validPromo}
                      </div>
                    )}

                  {/* TOTAL */}
                  <div className="order-summary__item-total">
                    <span className="order-summary__item-total--left">
                      {stepThree ||
                      matchCurrentLocation(Constants.Pages.ORDER_CONFIRMATION)
                        ? 'Recurring Monthly Bill'
                        : 'Estimated Recurring Monthly Bill'}
                    </span>
                    {!isCartEmpty ? (
                      <span className="order-summary__item-total--right-bold">
                        $
                        {matchCurrentLocation(Constants.Pages.CART) &&
                          (orderPrice.total - orderPrice.tax).toFixed(2)}
                        {!matchCurrentLocation(Constants.Pages.CART) &&
                          (stepTwo
                            ? (orderPrice.total - orderPrice.tax).toFixed(2)
                            : orderPrice.total.toFixed(2))}
                      </span>
                    ) : (
                      <span className="order-summary__item-total--right-bold">
                        $0.00
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <div className="order-summary__empty-cart-msg">
                  <p>There are no subscriptions in your shopping cart.</p>
                  <p>
                    Subscription services must be purchased separately from
                    other items.
                  </p>
                </div>
              )}

              {/* PROMO CODE FIELD */}
              {matchCurrentLocation(Constants.Pages.CHECKOUT) &&
                (!promoFieldVisibility ? (
                  <button
                    className="order-summary__add-promo-btn"
                    type="button"
                    onClick={() => setPromoFieldVisibility(true)}
                  >
                    <PlusIcon className="plus-icon" />
                    Add Promo Code
                  </button>
                ) : (
                  <form className="order-summary__promo-controls">
                    <label htmlFor="promo-code">Promo Code:</label>
                    <input
                      type="text"
                      id="promo-code"
                      name="promo-code"
                      value={promoCode}
                      required={true}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                    {promoError && (
                      <span>The promo code you have entered is not valid</span>
                    )}

                    <ButtonCTA
                      variant="primary"
                      type="submit"
                      onClick={(e) => handleApplyPromoCode(e)}
                    >
                      Apply Promo Code
                    </ButtonCTA>

                    <ButtonCTA
                      variant="secondary"
                      onClick={() => {
                        setPromoCode('');
                        setPromoError(false);
                        setPromoFieldVisibility(false);
                      }}
                    >
                      Cancel Adding Promo Code
                    </ButtonCTA>
                  </form>
                ))}

              {/* CTA */}
              {stepTwo && !promoFieldVisibility && (
                <ButtonCTA
                  variant="primary"
                  className="order-summary__submit-btn"
                  disabled={!agreementCheck || isLoading || !checkoutValid}
                  onClick={continueToPaymentHandler}
                >
                  {isLoading ? (
                    <div className="spinner"></div>
                  ) : (
                    'Continue to Secure Payment'
                  )}
                </ButtonCTA>
              )}

              {stepThree && !promoFieldVisibility && (
                <div className="order-summary__agreement">
                  <span className="order-summary__agreement--text">
                    By clicking Submit My Order, I agree to the{' '}
                    <span className="order-summary__agreement--link">
                      Terms & Conditions
                    </span>
                    .
                  </span>

                  <ButtonCTA
                    variant="primary"
                    className="order-summary__submit-btn"
                    onClick={submitOrderHandler}
                    disabled={isLoading}
                  >
                    <span id="button-text">
                      {isLoading ? (
                        <div className="spinner"></div>
                      ) : (
                        'Submit My Order'
                      )}
                    </span>
                  </ButtonCTA>
                </div>
              )}

              {matchCurrentLocation(Constants.Pages.CART) && (
                <ButtonCTA
                  variant="primary"
                  className="order-summary__submit-btn"
                  onClick={() => {
                    if (false) {
                      // UserService.doLogin({
                      //   redirectUri:
                      //     `${window.location.origin}` +
                      //     prefixPath(Constants.Pages.CHECKOUT, i18n.language),
                      //   idpHint: Keycloak.DEFAULT_IDP,
                      // });
                    } else
                      history.push(
                        prefixPath(Constants.Pages.CHECKOUT, i18n.language)
                      );
                  }}
                  disabled={!isCartEmpty ? false : true}
                >
                  Continue to Checkout
                </ButtonCTA>
              )}

              {!promoFieldVisibility && (
                <ButtonCTA
                  variant="primary"
                  className="order-summary__view-summary-btn"
                  onClick={() => {
                    setViewSummary((prev) => !prev);
                  }}
                >
                  {!viewSummary ? 'View Order Summary' : 'Close Order Summary'}
                </ButtonCTA>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
