import React from 'react';

interface IProps {
  orderItem: {
    item: string;
    price: number;
  };
}

const OrderSummaryItem: React.FC<IProps> = ({ orderItem }) => {
  return (
    <div className="order-summary-item">
      <span className="order-summary-item__name">{orderItem.item}</span>
      <span className="order-summary-item__price">
        ${orderItem.price.toFixed(2)}
      </span>
    </div>
  );
};

export default OrderSummaryItem;
