import React from 'react';
import ReactDOM from 'react-dom';

import { store } from './global/store/store';
import App from '@global/App/index';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from '@apollo/client';
import { client } from '@global/Api';
import UserService from '@global/Api/keycloak/UserService';
import '@global/localization/i18n';

//const renderApp = () =>
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
// UserService.initKeycloak(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
