import { gql } from '@apollo/client';

export const CART_BY_CART_ID = gql`
  query getCustomerOrderByBiId($biId: String!) {
    getCustomerOrderByBiId(biId: $biId) {
      biId
      id
      paybPayment {
        ... on StripeCustomerPeriodicCreditCardPayment {
          ccpaNameOnCard
          ccpaCardNumber
          ccpaExpirationDate
        }
      }
      wContactPhone
      wTOSAccepted
      wMailingListSubscriptionAccepted
      paybBillingAddress {
        uid
        id
        gadCountry {
          name
          uid
          numericCode
        }
        gadStateOrProvince
        upaLine1
        upaLine2
        upaPostcode
        upaCity
      }
    }
  }
`;

export const PAY_MUTATION = gql`
  mutation pay($biId: String!, $token: String!) {
    wileyPay(input: { token: $token, biId: $biId }) {
      result
    }
  }
`;

export const PLACE_CUSTOMER_ORDER_MUTATION = gql`
  mutation placeCustomerOrder($biId: String!, $token: String!) {
    placeCustomerOrder(input: { wPaymentToken: $token, biId: $biId }) {
      biId
      wPaymentToken
    }
  }
`;

export const REQUEST_CART = gql`
  query getCartExample($biId: String!) {
    getCustomerOrderByBiId(biId: $biId) {
      biId
      id
      biCreatedAt
      # order items
      pricPrice(_mapsOn: [{ code: "TAX" }, { code: "TOTAL" }]) {
        edges {
          mapsOn {
            code
          }
          node {
            amount
          }
        }
      }
      paybBillingAddress {
        uid
        id
        gadCountry {
          name
          uid
          numericCode
        }
        gadStateOrProvince
        upaLine1
        upaLine2
        upaPostcode
        upaCity
      }
      hiConsistsOf {
        # primary key; order item number
        biiId
        # sorting key
        biiSequentialId
        # product data
        biiProduct {
          uid
          sku
          name
          imageUrl
          wBookPublishDate
          wBookAuthors {
            uid
            wName
          }
          pricPrice(_mapsOn: { code: "PRICE" }) {
            edges {
              mapsOn {
                code
              }
              node {
                amount
              }
            }
          }
        }
      }
    }
  }
`;

export const LINK_USER_TO_CART = gql`
  mutation updateCartExample_attachCustomer($biId: String!, $paId: String!) {
    updateViaxCustomerOrder(
      input: {
        # order number you want to update
        biId: $biId
        # attach customer
        biParties: [
          {
            mapsOn: [{ code: "AUTHOR" }, { code: "SOLD_TO" }]
            node: { paId: $paId }
          }
        ]
      }
    ) {
      biId
      id
      biCreatedAt
      # order items
      hiConsistsOf {
        # primary key; order item number
        biiId
        # sorting key
        biiSequentialId
        # product data
        biiProduct {
          uid
          sku
          name
          imageUrl
          wBookPublishDate
          wBookAuthors {
            uid
            wName
          }
          pricPrice(_mapsOn: { code: "PRICE" }) {
            edges {
              mapsOn {
                code
              }
              node {
                amount
              }
            }
          }
        }
      }
    }
  }
`;
