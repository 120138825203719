export const Pages = {
  ROOT: '/',
  CART: '/cart',
  LOGIN: '/login',
  REGISTER: '/register',
  PDP_BASIC: '/pdp-basic',
  PDP1: '/chemistry-on-demand',
  PDP2: '/wiley-advantage',
  PDP3: '/vitalsource-bookshelf',
  ORDER_CONFIRMATION: '/checkout-confirmation',
  CHECKOUT: '/checkout',
  SUBSCRIPTION_DETAILS: '/subscription-details',
  ERR404: '/404',
};

export const PDP_DEFAULT_PRODUCT = process.env.REACT_APP_PDP_DEFAULT_PRODUCT;
export const CART_ID_ATTRIBUTE = process.env.REACT_APP_CART_ID_ATTRIBUTE;
export const DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT;

export const Keycloak = {
  REALM: process.env.REACT_APP_KC_REALM,
  CLIENT_ID: process.env.REACT_APP_KC_CLIENT_ID,
  AUTH_URL: process.env.REACT_APP_KC_AUTH_URL,
  DEFAULT_IDP: process.env.REACT_APP_KC_DEFAULT_IDP,
};

export const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI;

export const SKU = {
  default: PDP_DEFAULT_PRODUCT,
  en: '9781119904625',
  de: '9781119243700',
};
