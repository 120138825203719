import React from 'react';
import './style.scss';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'primary' | 'secondary';
}

const ButtonCTA: React.FC<IProps> = ({ children, variant, ...props }) => {
  return (
    <button
      type={props.type || 'button'}
      {...props}
      className={
        props.className ? `${variant}-cta ${props.className}` : `${variant}-cta`
      }
    >
      {children}
    </button>
  );
};

export default ButtonCTA;
