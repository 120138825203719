import React from 'react';
import '@global/components/Notification/style.scss';

interface IProps {
  notification: {
    type: string;
    message: string;
    effectClass?: string;
  };
}

const Notification: React.FC<IProps> = ({ notification }) => {
  return (
    <div
      id={`activeNotification`}
      className={`notification ${notification.type} ${notification.effectClass}`}
    >
      {notification.message}
    </div>
  );
};

export default Notification;
