import React from 'react';
import '@global/components/Footer/style.scss';

// Components
import RoutingElement from './components/RoutingElement';

const mainLinks = [
  { name: 'FOR CONSUMERS', link: '', type: 'int' }, // Needs link
  { name: 'FOR INSTITUTIONS & BUSINESSES', link: '', type: 'int' }, // Needs link
  { name: 'THE WILEY NETWORK', link: '/network', type: 'int' },
  { name: 'NEWSROOM', link: 'https://newsroom.wiley.com', type: 'ext' },
];

const accountLinks = [
  {
    name: 'ACCOUNT',
    link: 'https://customer.wiley.com/CGI-BIN/lansaweb?procfun+shopcart+shcfn11+funcparms+parmurl(l0350):https%3A%2F%2Fwww.wiley.com%2Fen-us(A0100):HYBRIS_US',
    type: 'ext',
  }, // Needs to be verified
  { name: 'My Account', link: '/subscriptions', type: 'int' }, // Needs to be verified
  { name: 'Help', link: 'https://support.wiley.com', type: 'ext' },
  { name: 'Contact Us', link: '/contactus', type: 'int' },
];

const generalLinks = [
  { name: 'Site Map', link: '/sitemap', type: 'int' },
  { name: 'Rights & Permissions', link: '/permissions', type: 'int' },
  { name: 'Privacy Policy', link: '/privacy', type: 'int' },
  { name: 'Terms of Use', link: '/terms-of-use', type: 'int' },
];

const aboutLinks = [
  { name: 'ABOUT WILEY', link: '/aboutus', type: 'int' },
  {
    name: 'Corporate Social Responsibility',
    link: '/corporatecitizenship',
    type: 'int',
  },
  { name: 'Corporate Governance', link: '/corporategovernance', type: 'int' },
  { name: 'Leadership Team', link: '/leadership-team', type: 'int' },
  { name: 'Investors', link: 'https://investors.wiley.com', type: 'ext' },
  { name: 'The Wiley Foundation', link: '', type: 'int' }, // Needs link
  { name: 'Careers', link: 'https://careers.wiley.com', type: 'ext' },
];

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__top">
          <div className="footer__main-links">
            {mainLinks.map((item) => {
              return <RoutingElement item={item} key={item.name} />;
            })}
          </div>

          <div className="footer__about-links">
            {aboutLinks.map((item) => {
              return <RoutingElement item={item} key={item.name} />;
            })}
          </div>

          <div className="footer__account-links">
            {accountLinks.map((item) => {
              return <RoutingElement item={item} key={item.name} />;
            })}
          </div>
        </div>

        <div className="footer__bottom">
          <div className="footer__general-links">
            {generalLinks.map((item) => {
              return <RoutingElement item={item} key={item.name} />;
            })}
          </div>

          <span className="footer__copyright">
            Copyright © 2022 Wiley Inc. All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
