// Modules
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { format } from 'date-fns';

// CSS and assets
import './style.scss';
import { ReactComponent as OpenNewWindowIcon } from '@assets/images/Open_New_Window_Icon.svg';

// Components
import Modal from '@src/global/components/Modal';
import ButtonCTA from '@src/global/components/UI Elements/ButtonCTA';
import BillingInfoChangeForm from './components/BillingInfoChangeForm';
import PaymentInfoChangeForm from './components/PaymentInfoChangeForm';
import SubscriptionProduct from './components/SubscriptionProduct';
import Notification from '@src/global/components/Notification';

// GraphQL
import {
  REQUEST_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  GET_SUBSCRIPTIONS,
  GET_VITAL_SOURCE,
} from './queries';
import { UPDATE_BILLING_INFO_MUTATION } from './components/BillingInfoChangeForm/queries';

// Utils
import getOrderPrice from '@global/hooks/getOrderPrice';
import UserService from '@global/Api/keycloak/UserService';
import { DATE_FORMAT, Pages } from '@global/App/constants/constants';
import { Keycloak } from '@global/App/constants/constants';

// Types
import { PurchasedSubscription } from '@src/global/types/types';

const Subscription: React.FunctionComponent = () => {
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [billingModalVisible, setBillingModalVisible] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [showNotification, setShowNotification] = useState<
    null | [string, string]
  >(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    UserService.isLoggedIn()
  );
  const [changeBillingInfo, setChangeBillingInfo] = useState({
    name: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    phone: '',
  });
  const [paybBillingAddress, setBillingAddress] = useState<{
    line1: string;
    line2?: string;
    zip: string;
    city: string;
    state: string;
  } | null>(null);

  const [paymnetInfo, setPaymnetInfo] = useState<{
    nameOnCard: string;
    cardNumber: string;
    expirationDate: string;
  } | null>(null);

  const [periodInfo, setPeriodInfo] = useState<{
    subscriptionBegan: string;
    nextBillDate: string;
    subscriptionEndDate: string;
  } | null>({
    subscriptionBegan: '',
    nextBillDate: '',
    subscriptionEndDate: '',
  });

  const history = useHistory();

  if (!isUserLoggedIn) {
    // UserService.doLogin({
    //   idpHint: Keycloak.DEFAULT_IDP,
    // });
    setIsUserLoggedIn(true);
  }

  // Mechanism to unmount notification after a period of time
  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setShowNotification(null);
      }, 4000);
    }
  }, [showNotification]);

  // Scroll to top of page on render.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let orderPrice: {
    total?: number;
    tax?: number;
    price?: number;
  } = {
    total: 0,
    tax: 0,
    price: 0,
  };

  const [cancelSubscriptionMutation] = useMutation(CANCEL_SUBSCRIPTION);
  const [updateBillingInfoMutation] = useMutation(UPDATE_BILLING_INFO_MUTATION);

  // Query all subscriptions
  const {
    data: {
      filterViaxSubscriptionContractBy: { page: subscriptions = [] } = {},
    } = {},
    loading: loadingAll,
  } = useQuery(GET_SUBSCRIPTIONS, {
    variables: {
      filter: `bpStatus=Active AND biParties.paId=\"${UserService.getUserUid()}\"`,
      orderBy: { field: 'biId', direction: 'DESC' },
    },
    fetchPolicy: 'network-only',
  }) as {
    data: {
      filterViaxSubscriptionContractBy: { page: PurchasedSubscription[] | [] };
    };
    loading: boolean;
  };

  // Get id of latest subscription
  const latestBiId = subscriptions[0]?.biId;

  // Query latest subscription
  const {
    data: subData,
    loading: loadingSub,
    refetch: reloadSubscriptionInfo,
  } = useQuery(REQUEST_SUBSCRIPTION, {
    variables: { biId: latestBiId ? latestBiId : '' },
    onCompleted: (data) => {
      if (data && data.getSubscriptionContractByBiId?.paybBillingAddress) {
        const address = data.getSubscriptionContractByBiId.paybBillingAddress;
        const payment = data.getSubscriptionContractByBiId.paybPayment;
        setBillingAddress({
          line1: address.upaLine1,
          line2: address.upaLine2,
          zip: address.upaPostcode,
          city: address.upaCity,
          state: address.gadStateOrProvince,
        });
        if (payment) {
          setPaymnetInfo({
            nameOnCard: payment.ccpaNameOnCard,
            cardNumber: payment.ccpaCardNumber,
            expirationDate: payment.ccpaExpirationDate,
          });
        }
        // Update the billing info for Chnage Billing Info form
        let address2 = address.upaLine2;
        if (address2 === 'null') {
          address2 = '';
        } else address2 = address.upaLine2;

        setChangeBillingInfo({
          name: payment.ccpaNameOnCard || '',
          address1: address.upaLine1 || '',
          address2: address2,
          zip: address.upaPostcode || '',
          city: address.upaCity || '',
          state: address.gadStateOrProvince || '',
          phone: data?.getSubscriptionContractByBiId?.wContactPhone || '',
        });
      }

      if (data && data.getSubscriptionContractByBiId?.subsValidPeriod) {
        const subsValidPeriod =
          data?.getSubscriptionContractByBiId?.subsValidPeriod;
        const paybNextBillDate = (data?.getSubscriptionContractByBiId
          ?.paybNextBillDate || '') as
          | string
          | { extensions: { code: string }; message: string };

        setPeriodInfo({
          subscriptionBegan: format(
            new Date(subsValidPeriod?.start),
            DATE_FORMAT
          ),
          nextBillDate:
            typeof paybNextBillDate !== 'string'
              ? paybNextBillDate?.extensions?.code
              : format(new Date(paybNextBillDate), DATE_FORMAT),
          subscriptionEndDate: format(
            new Date(subsValidPeriod?.end),
            DATE_FORMAT
          ),
        });
      }
    },
    onError: (err) => {
      console.error(err);
      setShowNotification([
        'error',
        'There was a problem retrieving your subscription.',
      ]);
    },
  });

  // VitalSource query
  const [getVSAccessLink, { loading: loadingVS }] = useLazyQuery(
    GET_VITAL_SOURCE,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  if (subData?.getSubscriptionContractByBiId?.paybPayment?.paymAmount) {
    orderPrice = getOrderPrice(
      subData.getSubscriptionContractByBiId.paybPayment?.paymAmount.edges
    );
  }

  const product = {
    sku: subData?.getSubscriptionContractByBiId?.hiConsistsOf[0]?.biiProduct
      ?.sku,
  };

  const tel = subData?.getSubscriptionContractByBiId?.wContactPhone || '';

  const handleCancel = async () => {
    const { data, errors } = await cancelSubscriptionMutation({
      variables: {
        biId: latestBiId,
      },
    });

    if (data?.cancelSubscriptionContract) {
      setCancelModalVisible(false);
      history.push(Pages.SUBSCRIPTION_DETAILS);
    }

    if (errors) {
      console.error(errors);
      setShowNotification([
        'error',
        'There was a problem with cancelling your subscription.',
      ]);
    }
  };

  const handleBillingInfoChange = async () => {
    const { data, errors } = await updateBillingInfoMutation({
      variables: {
        biId: latestBiId,
        uid: subData?.getSubscriptionContractByBiId?.paybPayment?.uid,
        nameCC: changeBillingInfo.name,
        addr1: changeBillingInfo.address1,
        addr2: changeBillingInfo.address2,
        zip: changeBillingInfo.zip,
        state: changeBillingInfo.state,
        city: changeBillingInfo.city,
        phone: changeBillingInfo.phone,
      },
    });

    if (data?.updateViaxSubscriptionContract) {
      reloadSubscriptionInfo();
      setBillingModalVisible(false);
      setShowNotification([
        'info',
        'Your billing information was updated successfully.',
      ]);
    }

    if (errors) {
      console.error(errors);
      setShowNotification([
        'error',
        'There was a problem with updating your billing information.',
      ]);
    }
  };

  const handlePaymentInfoChange = () => {
    // Logic for changing payment information
    console.log('Payment info form  submitted.');
  };

  const modalDataCancelSub = {
    content: (
      <span>
        When you cancel, you will lose access to this book on{' '}
        <span className="text-bold">{periodInfo?.nextBillDate}</span>. You will
        not be charged again.
      </span>
    ),
    actionBtnText: 'Cancel My Subscription',
    action: handleCancel,
    backBtnText: 'Back to Details',
  };

  const modalDataChangeBilling = {
    title: 'Change Billing Information',
    content: (
      <BillingInfoChangeForm
        changeBillingInfo={changeBillingInfo}
        setChangeBillingInfo={setChangeBillingInfo}
        handleBillingInfoChange={handleBillingInfoChange}
      />
    ),
    actionBtnText: 'Save Billing Information',
    action: handleBillingInfoChange,
    backBtnText: "Don't Change Billing Information",
  };

  const modalDataChangePayment = {
    title: 'Change Payment Method',
    content: (
      <PaymentInfoChangeForm
        handlePaymentInfoChange={handlePaymentInfoChange}
      />
    ),
    actionBtnText: 'Save Payment Method',
    action: handlePaymentInfoChange,
    backBtnText: "Don't Change Payment Method",
  };

  const handleVitalSource = async () => {
    const biId = subData?.getSubscriptionContractByBiId?.biId;

    const { data, error } = await getVSAccessLink({
      variables: {
        subscriptionContractBiId: biId,
      },
    });

    const accessLink =
      data?.wGetVitalSourceAccessLink120sec?.result?.redirectLink;

    if (accessLink) {
      window.open(accessLink, '_blank')?.focus();
    }

    if (error) {
      console.error(error);
      setShowNotification([
        'error',
        'There was an unexpected problem with VitalSource.',
      ]);
    }
  };

  if (loadingSub || loadingAll) {
    return <div className="page-spinner"></div>;
  }

  // If subscription does not exist
  if (!subData?.getSubscriptionContractByBiId) {
    return (
      <div className="no-sub">
        <div className="no-sub__info-wrapper">
          <span className="no-sub__title">
            {`${UserService.getUserFirstName()}'s Subscription Details`}
          </span>
          <span className="no-sub__message">
            You haven’t purchased a Wiley Chemistry on Demand subscription for
            your account{' '}
            <span className="no-sub__message--bold">
              {UserService.getUserEmail()}
            </span>
          </span>
          <ButtonCTA variant="primary">Free Trial / Checkout</ButtonCTA>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mobile-cta-container">
        <ButtonCTA
          variant="primary"
          onClick={handleVitalSource}
          disabled={loadingVS}
        >
          {loadingVS ? (
            <div className="spinner"></div>
          ) : (
            <span>
              Access Your VitalSource Bookshelf <OpenNewWindowIcon />
            </span>
          )}
        </ButtonCTA>
      </div>

      <div className="subscription">
        <main className="subscription__container">
          {showNotification && (
            <Notification
              notification={{
                type: showNotification[0],
                effectClass: 'fading sticky',
                message: showNotification[1],
              }}
            />
          )}
          <section className="subscription__data-active">
            <div className="subscription__item-wrapper">
              <div className="subscription__title-container">
                <span>
                  {UserService.getUserFirstName()}&apos;s Subscription Details
                </span>

                <ButtonCTA
                  variant="primary"
                  onClick={handleVitalSource}
                  disabled={loadingVS}
                >
                  {loadingVS ? (
                    <div className="spinner"></div>
                  ) : (
                    <span>
                      Access Your VitalSource Bookshelf <OpenNewWindowIcon />
                    </span>
                  )}
                </ButtonCTA>
              </div>

              {product.sku && (
                <SubscriptionProduct
                  sku={product.sku}
                  isActive={true}
                  periodInfo={periodInfo}
                  totalPrice={orderPrice.total.toFixed(2)}
                ></SubscriptionProduct>
              )}
            </div>

            <div className="subscription__content-wrapper">
              <div className="subscription__content">
                <div className="subscription__info-wrapper">
                  <span className="subscription__title">
                    Subscription Information
                  </span>
                  <div className="sub-information">
                    <div className="sub-information__column-container">
                      <div className="sub-information__column">
                        <span className="sub-information__text_gray">
                          Subscription Began
                        </span>
                        <span className="sub-information__text_black">
                          {periodInfo?.subscriptionBegan}
                        </span>
                      </div>
                      <div className="sub-information__column">
                        <span className="sub-information__text_gray">
                          Monthly Bill Breakdown
                        </span>
                        <span className="sub-information__text_black">
                          <span className="sub-infromation__text_black sub-information__text_price">
                            ${orderPrice.price.toFixed(2)}
                          </span>{' '}
                          Subscription Fee
                        </span>
                        <span className="sub-information__text_black">
                          <span className="sub-infromation__text_black sub-information__text_price">
                            ${orderPrice.tax.toFixed(2)}
                          </span>{' '}
                          Taxes
                        </span>
                      </div>
                    </div>

                    <button
                      className="subscription__cancel-btn"
                      onClick={() => setCancelModalVisible(true)}
                    >
                      Cancel My Subscription
                    </button>
                  </div>
                </div>

                <div className="subscription__billing-wrapper">
                  <span className="subscription__title">
                    Billing Information
                  </span>
                  <div className="sub-information__column-container">
                    {paybBillingAddress && paymnetInfo && (
                      <div className="sub-information__billing-container">
                        <div className="sub-information__column">
                          <span className="sub-information__text_bold">
                            {paymnetInfo.nameOnCard}
                          </span>
                          <span className="sub-information__text_black">
                            {tel}
                          </span>
                          <span className="sub-information__text_black">
                            {paybBillingAddress.line1}
                          </span>
                          <span className="sub-information__text_black">
                            {paybBillingAddress.line2 !== 'null' &&
                              paybBillingAddress.line2}
                          </span>
                          <span className="sub-information__text_black">
                            {paybBillingAddress.city},&nbsp;
                            {paybBillingAddress.zip}
                            &nbsp;
                            {paybBillingAddress.state}
                          </span>
                        </div>

                        <button
                          className="subscription__change-billing-btn"
                          onClick={() => setBillingModalVisible(true)}
                        >
                          Change Billing Information
                        </button>
                      </div>
                    )}

                    {paymnetInfo && (
                      <div className="sub-information__payment-container">
                        <div className="sub-information__column">
                          <span className="sub-information__text_gray">
                            Payment Method
                          </span>
                          <span className="sub-information__text_black">
                            {paymnetInfo.cardNumber}
                          </span>
                          <span className="sub-information__text_black">
                            {paymnetInfo?.expirationDate}
                          </span>
                        </div>

                        <button
                          className="sub-information__change-payment-btn"
                          onClick={() => setPaymentModalVisible(true)}
                        >
                          Change Payment Method
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Modal
          isModalVisible={cancelModalVisible}
          setIsModalVisible={setCancelModalVisible}
          modalData={modalDataCancelSub}
        />

        <Modal
          isModalVisible={billingModalVisible}
          setIsModalVisible={setBillingModalVisible}
          modalData={modalDataChangeBilling}
        />

        <Modal
          customClass="payment-modal"
          isModalVisible={paymentModalVisible}
          setIsModalVisible={setPaymentModalVisible}
          modalData={modalDataChangePayment}
        />
      </div>
    </>
  );
};

export default Subscription;
