// Modules
import React, { useState, useEffect, useRef } from 'react';

// CSS and assets
import './style.scss';
import { ReactComponent as Chevron } from '@assets/images/Chevron.svg';

interface IProps {
  open?: boolean;
  title: string;
  className?: string;
}

const Collapsible: React.FC<IProps> = ({
  open,
  title,
  className,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener('resize', () => setWidth(window.innerWidth));

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height + (width < 768 ? 8 : 16));
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div
      className={className ? `collapsible ${className}` : 'collapsible'}
      onClick={handleFilterOpening}
    >
      <div className="collapsible__wrapper">
        <span>{title}</span>
        <div className={!isOpen ? 'expand' : 'collapse'}>
          <Chevron />
        </div>
      </div>

      <div className="collapsible__content" style={{ height }}>
        <div ref={ref}>{children}</div>
      </div>
    </div>
  );
};

export default Collapsible;
