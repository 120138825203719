// Modules
import React, { Children, ReactChild } from 'react';
import { Link } from 'react-router-dom';

// CSS and assets
import './style.scss';

// Utils
import { Pages } from '@src/global/App/constants/constants';

interface IProps {
  size: 'sm' | 'md' | 'lg';
  imageURL: string;
  alt?: string;
  contentOffset?: boolean;
}

const HeroImage: React.FC<IProps> = ({
  size,
  imageURL,
  alt,
  contentOffset,
  children,
}) => {
  const childrenArr = Children.toArray(children);

  const title = childrenArr.filter((el: ReactChild) => el['type'] === 'h3')[0];
  const content = childrenArr.filter((el: ReactChild) => el['type'] === 'p')[0];
  const ctaBtn = childrenArr.filter(
    (el: ReactChild) => typeof el['type'] === 'function'
  )[0];

  return (
    <div className="hero-img-wrapper">
      <div className={`hero-img hero-size-${size}`}>
        <span
          className="hero-img__overlay-img"
          role="img"
          aria-label={alt}
          style={{ backgroundImage: `url(${imageURL})` }}
        ></span>

        <div
          className={
            'hero-img__content-wrapper' +
            (contentOffset ? ' content-offset' : '')
          }
        >
          {title}
          {content}
          {ctaBtn && <Link to={Pages.CHECKOUT}>{ctaBtn}</Link>}
        </div>
      </div>

      {content && <div className="mobile-content">{content}</div>}
    </div>
  );
};

export default HeroImage;
