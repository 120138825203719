// Modules
import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

// CSS and assets
import './style.scss';

// Utils
import UserService from '@global/Api/keycloak/UserService';
import { Pages } from '@global/App/constants/constants';
import { prefixPath } from '@global/localization/services/services';
import { Keycloak } from '@global/App/constants/constants';

interface IProps {
  isLogged?: boolean;
}

const UserDropdown: React.FC<IProps> = ({ isLogged }) => {
  return (
    <div
      className={
        isLogged
          ? 'user-dropdown user-dropdown__logged-in'
          : 'user-dropdown user-dropdown__logged-out'
      }
    >
      <div className="user-dropdown__selection-box">
        {!isLogged ? (
          <button
            className="user-dropdown__login-btn"
            onClick={
              () => {
                return true;
              }
              // UserService.doLogin({
              //   idpHint: Keycloak.DEFAULT_IDP,
              // })
            }
          >
            Login {UserService.isLoggedIn()}
          </button>
        ) : (
          <>
            <Link to={prefixPath(Pages.SUBSCRIPTION_DETAILS, i18n.language)}>
              <button className="user-dropdown__my-account-btn">
                Subscription Details
              </button>
            </Link>
            <button
              className="user-dropdown__logout-btn"
              onClick={() => UserService.doLogout()}
            >
              Logout {UserService.isLoggedIn()}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default UserDropdown;
