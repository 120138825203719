// Modules
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import i18n from 'i18next';

// CSS and assets
import './style.scss';
import { ReactComponent as CheckmarkIcon } from '@assets/images/Plain_Checkmark.svg';

// Utils
import { supportedLngs } from '@src/global/localization/i18n';

const LanguageDropdown: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const onLanguageChanged = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newLang = event.currentTarget.value;
    const oldLang = i18n.language;
    i18n.changeLanguage(newLang);

    const currentPath = location.pathname.replace('/' + oldLang, '/' + newLang);

    history.push(currentPath);
  };

  return (
    <div className="lang-dropdown">
      <div className="lang-dropdown__selection-box">
        {supportedLngs.map((lang) => {
          return (
            <button
              key={lang.code}
              value={lang.code}
              onClick={(value) => onLanguageChanged(value)}
            >
              <span
                className={i18n.language === lang.code ? 'selected-lang' : ''}
              >
                {i18n.language === lang.code && <CheckmarkIcon />} {lang.lang}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default LanguageDropdown;
