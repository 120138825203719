// Modules
import React, { Children, ReactChild } from 'react';
import { Link } from 'react-router-dom';

// CSS and assets
import './style.scss';

// Utils
import { Pages } from '@src/global/App/constants/constants';

interface IProps {
  imageURL: string;
  alt?: string;
  learnMoreURL?: string;
}
const StackablePanelFullBleedImg: React.FC<IProps> = ({
  imageURL,
  alt,
  learnMoreURL,
  children,
}) => {
  const childrenArr = Children.toArray(children);

  const title = childrenArr.filter((el: ReactChild) => el['type'] === 'h3')[0];
  const content = childrenArr.filter((el: ReactChild) => el['type'] === 'p')[0];
  const ctaBtn = childrenArr.filter(
    (el: ReactChild) => typeof el['type'] === 'function'
  )[0];

  return (
    <div className="stack-panel-img">
      <span
        className="stack-panel-img__overlay-img"
        role="img"
        aria-label={alt}
        style={{ backgroundImage: `url(${imageURL})` }}
      ></span>

      <div className="stack-panel-img__content-wrapper">
        {title}
        {content}
        {(ctaBtn || learnMoreURL) && (
          <div className="stack-panel-img__cta-wrapper">
            {ctaBtn && <Link to={Pages.CHECKOUT}>{ctaBtn}</Link>}
            {learnMoreURL && (
              <Link className="stack-panel-img__learn-more" to={learnMoreURL}>
                Learn more
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StackablePanelFullBleedImg;
