// Modules
import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import i18n from 'i18next';

// CSS and assets
import '@global/components/Header/style.scss';

// Components
import { ReactComponent as Logo } from '@assets/images/wiley_logo.svg';
import SecondaryNav from '@src/global/components/Header/components/SecondaryNav';
import MenuNavigation from './components/MenuNavigation';

// Utils
import { Pages } from '@global/App/constants/constants';
import { matchCurrentLocation } from '@src/global/utils';

interface IProps {
  isLogged?: boolean;
}

const Header: React.FC<IProps> = ({ isLogged }) => {
  const location = useLocation();
  let title = '';

  if (matchCurrentLocation(Pages.CHECKOUT)) {
    title = 'Subscription Checkout';
  }

  return (
    <header className="navigation-container">
      <div className={`header ${!title ? 'header_logged' : ''}`}>
        <div className="header__container">
          <Link to={`/`}>
            <Logo title="Wiley" className="header__logo" />
          </Link>

          {title && <span className="header__subheading">{title}</span>}

          {!matchCurrentLocation(Pages.CHECKOUT) && (
            <MenuNavigation isLogged={isLogged} />
          )}
        </div>
      </div>

      {location.pathname !== `/${i18n.language}${Pages.CHECKOUT}` && (
        <SecondaryNav />
      )}
    </header>
  );
};

export default Header;
