import { gql } from '@apollo/client';

export const CART_BY_CART_ID = gql`
  query getCustomerOrderByBiId($biId: String!) {
    getCustomerOrderByBiId(biId: $biId) {
      biId
      paybPayment {
        ... on StripeCustomerPeriodicCreditCardPayment {
          ccpaNameOnCard
          ccpaCardNumber
          ccpaExpirationDate
        }
      }
      wContactPhone
      wTOSAccepted
      wMailingListSubscriptionAccepted
      paybBillingAddress {
        uid
        id
        gadCountry {
          name
          uid
          numericCode
        }
        gadStateOrProvince
        upaLine1
        upaLine2
        upaPostcode
        upaCity
      }
    }
  }
`;

export const UPDATE_BILLING_INFORMATION = gql`
  mutation updateViaxCustomerOrder($input: UpdateViaxCustomerOrderInput!) {
    updateViaxCustomerOrder(input: $input) {
      biId
      id
      uid
      paybPayment {
        ... on StripeCustomerPeriodicCreditCardPayment {
          ccpaNameOnCard
          ccpaCardNumber
          ccpaExpirationDate
        }
      }
      wContactPhone
      wTOSAccepted
      wMailingListSubscriptionAccepted
      pricPrice(_mapsOn: [{ code: "TAX" }, { code: "TOTAL" }]) {
        edges {
          mapsOn {
            code
          }
          node {
            amount
          }
        }
      }
      paybBillingAddress {
        uid
        id
        gadCountry {
          name
          uid
          numericCode
        }
        gadStateOrProvince
        upaLine1
        upaLine2
        upaPostcode
        upaCity
      }
    }
  }
`;
