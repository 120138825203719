// Modules
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// CSS and assets
import './style.scss';

//Components
import { CardElement } from '@stripe/react-stripe-js';

interface IProps {
  handlePaymentInfoChange: () => void;
}

const PaymentInfoChangeForm: React.FC<IProps> = ({
  handlePaymentInfoChange,
}) => {
  // Load payment stripe
  const [stripePromise] = useState(() =>
    loadStripe(
      'pk_test_51JyZudGNIybthUSGnnY9C5qAgadOszJi2FOXbOJoAdWmwzNE0XUmc1pA0jaYBCBkPRefJT1pcHHkw3reYxzISVaI00f2cuqRvz'
    )
  );

  const paymentElementsStyling = {
    style: {
      base: {
        color: '#313131',
        fontWeight: '400',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#313131',
        },
        '::placeholder': {
          color: '#313131',
        },
      },
      invalid: {
        color: '#313131',
      },
    },
    hidePostalCode: true,
  };

  return (
    <Elements stripe={stripePromise}>
      <form id="change-payment-form" onSubmit={handlePaymentInfoChange}>
        <CardElement options={paymentElementsStyling} />
      </form>
    </Elements>
  );
};

export default PaymentInfoChangeForm;
