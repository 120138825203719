// Modules
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

// CSS and assets
import '@pages/checkout/components/BillingInformation/style.scss';
import { ReactComponent as CheckboxUnchecked } from '@assets/images/Checkbox_Unchecked.svg';
import { ReactComponent as CheckboxChecked } from '@assets/images/Checkbox_Checked.svg';
import { ReactComponent as PlusIcon } from '@assets/images/plus_icon.svg';

// Components
import ButtonCTA from '@src/global/components/UI Elements/ButtonCTA';

//Graphql
import { UPDATE_BILLING_INFORMATION } from './queries';

// Utils
import { mapToServer } from './helper';
import { US_STATES } from '@src/global/App/constants/location/constants';
import { CART_ID_ATTRIBUTE } from '@global/App/constants/constants';

interface IProps {
  agreementCheck: boolean;
  checkoutValid: boolean;
  setAgreementCheck: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckoutValid: React.Dispatch<React.SetStateAction<boolean>>;
  editStepHandler: (step: number) => void;
  billingInfo: {
    name: string;
    tel: string;
    addressOne: string;
    addressTwo: string;
    city: string;
    state: string;
    zip: string;
    uid?: string;
  };
  setBillingInfo: React.Dispatch<
    React.SetStateAction<{
      name: string;
      tel: string;
      addressOne: string;
      addressTwo: string;
      city: string;
      state: string;
      zip: string;
      uid?: string;
    }>
  >;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  addressTwoVisibility?: boolean;
  setAddressTwoVisibility?: React.Dispatch<React.SetStateAction<boolean>>;
}

const BillingInformation: React.FC<IProps> = ({
  agreementCheck,
  setAgreementCheck,
  checkoutValid,
  setCheckoutValid,
  editStepHandler,
  billingInfo,
  setBillingInfo,
  isLoading,
  setIsLoading,
  addressTwoVisibility,
  setAddressTwoVisibility,
}) => {
  const [offersCheck, setOffersCheck] = useState(false);

  const [updateBillingInfoMutation] = useMutation(UPDATE_BILLING_INFORMATION);
  const cart_id = localStorage.getItem(CART_ID_ATTRIBUTE);

  //React Hook Form
  const {
    register,
    watch,
    formState: { errors: rhfErrors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'all',
  });

  const billingName = watch('billingName');
  const billingAddress1 = watch('billingAddress1');
  const billingAddress2 = watch('billingAddress2');
  const billingZip = watch('billingZip');
  const billingCity = watch('billingCity');
  const billingState = watch('billingState');
  const billingPhone = watch('billingPhone');

  const normalizePhoneNumber = (value) => {
    const USNum = value
      .replace(/\D/g, '')
      .slice(-10)
      .match(/(\d?)(\d?)(\d?)(\d{0,3})(\d{0,4})/);
    let num = '';
    num += USNum[1] ? `(${USNum[1]}` : '';
    num += USNum[2] ? USNum[2] : '';
    num += USNum[3] ? `${USNum[3]}` : '';
    num += USNum[4] ? `) ${USNum[4]}` : '';
    num += USNum[5] ? `-${USNum[5]}` : '';
    return num || '';
  };

  // Clear errors on component render
  useEffect(() => {
    setCheckoutValid(isValid);
  }, [isValid]);

  //TODO: rebuild with native React Hook Form onChange functions
  useEffect(() => {
    setBillingInfo({
      ...billingInfo,
      name: `${billingName}`,
      addressOne: `${billingAddress1}`,
      addressTwo: billingAddress2,
      city: `${billingCity}`,
      state: `${billingState}`,
      zip: `${billingZip}`,
      tel: `${billingPhone}`,
    });
  }, [
    billingName,
    billingAddress1,
    billingAddress2,
    billingCity,
    billingState,
    billingZip,
    billingPhone,
  ]);

  // Note: barebones functionality and no validation for demo purposees
  const formSubmitHandler = async () => {
    setIsLoading(true);

    await updateBillingInfoMutation({
      variables: {
        input: {
          biId: cart_id,
          wTOSAccepted: agreementCheck,
          ...mapToServer(billingInfo),
        },
      },
    });

    setIsLoading(false);
    editStepHandler(3);
  };

  return (
    <div className="billing-info">
      <span className="billing-info__info regularText">
        Your billing information is used to verify your credit card, or contact
        you if there are any issues placing your order.
      </span>
      <span className="billing-info__reqd regularText">*Required Fields</span>

      <form
        className="billing-info__form"
        onSubmit={handleSubmit(formSubmitHandler)}
      >
        {/* NAME */}
        <div
          className={
            'billing-info__name-field ' +
            'active-validation-field' +
            (rhfErrors.billingName ? ' validation-error' : '') +
            (billingName ? ' validation-success' : '')
          }
        >
          <label htmlFor="billingName">*Name on Credit Card:</label>
          <input
            type="text"
            defaultValue={billingInfo.name}
            {...register('billingName', {
              required: 'Invalid name entered.',
              pattern: {
                value: /^[a-zA-Z ']+$/,
                message: 'Please, remove digits or special symbols from Name.',
              },
            })}
          />
          {rhfErrors.billingName && (
            <span className="error">{rhfErrors.billingName.message}</span>
          )}
        </div>

        {/* ADDRESS */}
        <div className="billing-info__address-field">
          <div
            className={
              'active-validation-field' +
              (rhfErrors.billingAddress1 ? ' validation-error' : '') +
              (billingAddress1 ? ' validation-success' : '')
            }
          >
            <label htmlFor="billingAddress1">*Address Line 1</label>
            <input
              id={'billingAddress1'}
              defaultValue={billingInfo.addressOne}
              {...register('billingAddress1', {
                required: 'Address Line 1 is required.',
                minLength: { value: 4, message: 'Minimum address length is 4' },
              })}
            />
            {rhfErrors.billingAddress1 && (
              <span className="error">{rhfErrors.billingAddress1.message}</span>
            )}
          </div>
          {addressTwoVisibility && (
            <div
              className={
                'active-validation-field' +
                (rhfErrors.billingAddress2 ? ' validation-error' : '') +
                (billingAddress2 ? ' validation-success' : '')
              }
            >
              <label htmlFor="billingAddress2">Address Line 2</label>
              <input
                id={'billingAddress2'}
                type="text"
                defaultValue={billingInfo.addressTwo}
                className={
                  'input-info' +
                  (rhfErrors.billingAddress2 ? ' input-error' : '') +
                  (billingAddress2 ? ' input-success' : '')
                }
                {...register('billingAddress2', {
                  minLength: {
                    value: 4,
                    message: 'Minimum address length is 4',
                  },
                })}
              />
              {rhfErrors.billingAddress2 && (
                <span className="error">
                  {rhfErrors.billingAddress2.message}
                </span>
              )}
            </div>
          )}
        </div>

        {!addressTwoVisibility && (
          <button
            className="billing-info__add-address-btn"
            type="button"
            onClick={() => setAddressTwoVisibility((prev) => !prev)}
          >
            <PlusIcon className="plus-icon" />
            Add Address Line 2
          </button>
        )}

        {/* ZIP CODE */}
        <div
          className={
            'billing-info__zip-field ' +
            'active-validation-field' +
            (rhfErrors.billingZip ? ' validation-error' : '') +
            (billingZip ? ' validation-success' : '')
          }
        >
          <label htmlFor="billingZip">*ZIP Code:</label>
          <input
            type="text"
            id="billingZip"
            defaultValue={billingInfo.zip}
            {...register('billingZip', {
              required: 'Zip Code is required.',
            })}
          />
          {rhfErrors.billingZip && (
            <span className="error">{rhfErrors.billingZip.message}</span>
          )}
        </div>

        {/* CITY */}
        <div
          className={
            'billing-info__city-field ' +
            'active-validation-field' +
            (rhfErrors.billingCity ? ' validation-error' : '') +
            (billingCity ? ' validation-success' : '')
          }
        >
          <label htmlFor="billingCity">*City:</label>
          <input
            type="text"
            id="billingCity"
            defaultValue={billingInfo.city}
            {...register('billingCity', {
              required: 'Please, specify your city.',
            })}
          />
          {rhfErrors.billingCity && (
            <span className="error">{rhfErrors.billingCity.message}</span>
          )}
        </div>

        {/* STATE */}
        <div
          className={
            'billing-info__state-field ' +
            'active-validation-field' +
            (rhfErrors.billingState ? ' validation-error' : '') +
            (billingState ? ' validation-success' : '')
          }
        >
          <label htmlFor="billingState">*State:</label>
          <div>
            <select
              id="billingState"
              defaultValue={billingInfo.state}
              {...register('billingState', {
                required: 'Please, specify your State.',
              })}
            >
              <option hidden></option>
              {US_STATES.map((state) => {
                return (
                  <option key={state} value={state}>
                    {state}
                  </option>
                );
              })}
            </select>
            {rhfErrors.billingState && (
              <span className="error">{rhfErrors.billingState.message}</span>
            )}
          </div>
        </div>

        {/* PHONE NUMBER */}
        <div
          className={
            'billing-info__phone-field ' +
            'active-validation-field' +
            (rhfErrors.billingPhone ? ' validation-error' : '') +
            (billingPhone ? ' validation-success' : '')
          }
        >
          <label htmlFor="billingPhone">*Phone Number:</label>
          <input
            type="tel"
            placeholder="(XXX) XXX-XXXX"
            id="billingPhone"
            defaultValue={billingInfo.tel}
            {...register('billingPhone', {
              required: 'Your phone is required',
              pattern: {
                value: /\([0-9]{3}\) [0-9]{3}[-][0-9]{4}$/,
                message:
                  'Please, specify your phone correctly like (XXX) XXX-XXXX',
              },
              onChange: (e) => {
                const { value, selectionStart } = e.target;
                e.target.value = normalizePhoneNumber(value);
                if (e.target.selectionEnd > 0 && e.target.selectionEnd < 4) {
                  e.target.selectionEnd = selectionStart + 1;
                }
                setValue('billingPhone', e.target.value, { shouldDirty: true });
              },
            })}
            maxLength={14}
          />
          {rhfErrors.billingPhone && (
            <span className="error">{rhfErrors.billingPhone.message}</span>
          )}
        </div>

        <div
          className="billing-info__payment-agreement"
          onClick={() => setAgreementCheck((prev) => !prev)}
        >
          {!agreementCheck ? (
            <CheckboxUnchecked className="checkbox-unchecked" />
          ) : (
            <CheckboxChecked className="checkbox-checked" />
          )}
          <span className="regularText">
            I agree to pay the monthly subscription fee plus any applicable
            taxes until my subscription is cancelled.
          </span>
        </div>

        <div
          className="billing-info__offers-agreement"
          onClick={() => setOffersCheck((prev) => !prev)}
        >
          {!offersCheck ? (
            <CheckboxUnchecked className="checkbox-unchecked" />
          ) : (
            <CheckboxChecked className="checkbox-checked" />
          )}

          <span className="regularText">
            I’d like to receive updates and offers from Wiley.
          </span>
        </div>

        <ButtonCTA
          variant="primary"
          className="billing-info__submit-btn"
          type="submit"
          id="continue-to-payment-btn"
          disabled={!agreementCheck || isLoading || !checkoutValid}
        >
          {isLoading ? (
            <div className="spinner"></div>
          ) : (
            'Continue to Secure Payment'
          )}
        </ButtonCTA>
      </form>
    </div>
  );
};

export default BillingInformation;
