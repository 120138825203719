import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// CSS and assets
import '@pages/login/style.scss';

// Utils
import { useForm } from 'react-hook-form';
import ButtonCTA from '@global/components/UI Elements/ButtonCTA';

const LoginPage: React.FC = () => {
  //React Hook Form
  const {
    register,
    watch,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'all',
  });

  // Scroll to top of page on render.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loginEmail = watch('loginEmail');
  const loginPassword = watch('loginPassword');

  const formSubmitHandler = async () => {
    return true;
  };

  return (
    <main className="page-login">
      <div className="page-title underlinedBlock">
        <div className="inner-wrapper">
          <h1>Login</h1>
        </div>
      </div>
      <div className="page-body">
        <div className="inner-wrapper">
          <div className="create-account">
            <Link className="underlined-link" to={`/register`}>
              Create a new account
            </Link>
          </div>
          <form
            className="active-form"
            id="loginForm"
            onSubmit={handleSubmit(formSubmitHandler)}
          >
            <div className="form-description">*Required Fields</div>
            <div
              className={
                'active-validation-field' +
                ' email-field' +
                (errors.loginEmail ? ' validation-error' : '') +
                (loginEmail ? ' validation-success' : '')
              }
            >
              <label htmlFor="loginEmail">*Email Address:</label>
              <input
                type="text"
                {...register('loginEmail', {
                  required: 'E-Mail required for login.',
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Type E-Mail correctly',
                  },
                })}
              />
              {errors.loginEmail && (
                <span className="error">{errors.loginEmail.message}</span>
              )}
            </div>
            <div
              className={
                'active-validation-field' +
                ' password-field' +
                (errors.loginPassword ? ' validation-error' : '') +
                (loginPassword ? ' validation-success' : '')
              }
            >
              <label htmlFor="loginEmail">*Password:</label>
              <input
                type="text"
                {...register('loginPassword', {
                  required: 'Password required for login.',
                })}
              />
              {errors.loginPassword && (
                <span className="error">{errors.loginPassword.message}</span>
              )}
            </div>
            <div className="submit-block">
              <ButtonCTA
                variant="primary"
                className="extra-class submit-btn billing-info__submit-btn"
                type="submit"
                id="continue-to-payment-btn"
                disabled={!isValid}
              >
                Log In
              </ButtonCTA>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
