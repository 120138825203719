import React, { useState, useEffect } from 'react';
import '@pages/checkout/components/AccountLogin/style.scss';

interface IProps {
  props: {
    setUser: React.Dispatch<React.SetStateAction<string>>;
    setLoginFormVisibility: React.Dispatch<React.SetStateAction<boolean>>;
    setCreateAccountFormVisibility: React.Dispatch<
      React.SetStateAction<boolean>
    >;
    editStepHandler: (step: number) => void;
    errors: {
      stepOne: {
        loginInfo: boolean;
        createEmail: boolean;
      };
      stepTwo: {
        nameCC: boolean;
      };
      stepThree: {
        invalidDetails: boolean;
      };
    };
    setErrors: React.Dispatch<
      React.SetStateAction<{
        stepOne: {
          loginInfo: boolean;
          createEmail: boolean;
        };
        stepTwo: {
          nameCC: boolean;
        };
        stepThree: {
          invalidDetails: boolean;
        };
      }>
    >;
  };
}

const AccountLogin: React.FC<IProps> = ({
  props: {
    setUser,
    setLoginFormVisibility,
    setCreateAccountFormVisibility,
    editStepHandler,
    errors,
    setErrors,
  },
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Clear errors on component render
  useEffect(() => {
    setErrors((prev) => {
      return {
        ...prev,
        stepOne: {
          loginInfo: false,
          createEmail: false,
        },
      };
    });
  }, []);

  const sectionToggleHandler = () => {
    setLoginFormVisibility(false);
    setCreateAccountFormVisibility(true);
  };

  // Note: barebones functionality and no validation for demo purposees
  const formSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    // Update later to check for valid email/password
    if (email.toLocaleLowerCase() === 'error@error') {
      setErrors((prev) => {
        return {
          ...prev,
          stepOne: {
            loginInfo: true,
            createEmail: prev.stepOne.createEmail,
          },
        };
      });
    } else if (email) {
      setErrors((prev) => {
        return {
          ...prev,
          stepOne: {
            loginInfo: false,
            createEmail: prev.stepOne.createEmail,
          },
        };
      });
      console.log('Form Submitted Successfully');
      editStepHandler(2);
      setUser(email);
    }
  };

  return (
    <div className="login-account">
      <button className="create-account-btn" onClick={sectionToggleHandler}>
        Create a new account
      </button>
      {errors.stepOne.loginInfo && (
        <span className="login-error-msg">Incorrect username or password.</span>
      )}
      <span className="form-text">*Required Fields</span>

      <form
        className="login-account__form"
        onSubmit={(e) => formSubmitHandler(e)}
      >
        {/* EMAIL */}
        <div
          className={
            errors.stepOne.loginInfo
              ? 'login-account__email-field--error'
              : 'login-account__email-field'
          }
        >
          <label htmlFor="checkout-login-email">*Email Address:</label>
          <input
            type="email"
            id="checkout-login-email"
            name="checkout-login-email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        {/* PASSWORD */}
        <div
          className={
            errors.stepOne.loginInfo
              ? 'login-account__password-field--error'
              : 'login-account__password-field'
          }
        >
          <label htmlFor="checkout-login-password">*Password:</label>
          <input
            type="password"
            id="checkout-login-password"
            name="checkout-login-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <button className="login-account__submit-btn" type="submit">
          Log In
        </button>
      </form>
    </div>
  );
};

export default AccountLogin;
