import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import de from './de.json';

export const supportedLngs = [
  { code: 'en', lang: 'English' },
  { code: 'de', lang: 'German' },
];
let language = localStorage.getItem('i18nextLng');

if (supportedLngs.indexOf(language) === -1) {
  language = 'en';
}

const optionsLang = {
  order: ['path', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag'],

  // keys or params to lookup language from
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translations: en },
      de: { translations: de },
    },
    fallbackLng: 'en',
    supportedLngs: supportedLngs.map((lang) => lang.code),
    detection: optionsLang,
    // debug only when not in production
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
