import { gql } from '@apollo/client';

export const REQUEST_SUBSCRIPTION = gql`
  query getSubscriptionContractByBiId($biId: String!) {
    getSubscriptionContractByBiId(biId: $biId) {
      biId

      bpStatus {
        code
      }

      # Product data
      hiConsistsOf {
        biiProduct {
          sku
          uid
        }
      }

      # Next Bill Date
      paybNextBillDate

      # Recurring Monthly Bill $$
      # Monthly Bill Breakdown

      wContactPhone

      paybPayment {
        uid
        paymAmount {
          edges {
            # mapsOn.code="TOTAL" ==>  Recurring Monthly Bill $$
            mapsOn {
              code
            }
            node {
              amount
            }
          }
        }

        ... on StripeCustomerPeriodicCreditCardPayment {
          ccpaNameOnCard
          ccpaCardNumber
          ccpaExpirationDate
        }
      }

      # Vital Source code
      wVitalSourceCode

      # Subscription Began
      subsValidPeriod {
        start
        end
      }

      # Subscription Cancelled
      subsTerminationDate

      # Phone
      hiPartOf {
        id
        hiPartOf {
          id
          biId
          ... on ViaxCustomerOrder {
            wContactPhone
            wTOSAccepted
            wMailingListSubscriptionAccepted
          }
        }
      }

      # Billing Information
      paybBillingAddress {
        upaLine1
        upaLine2
        upaCity
        gadStateOrProvince
        upaPostcode
      }
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubsription($biId: String!) {
    cancelSubscriptionContract(input: { biId: $biId }) {
      biId
      bpStatus {
        code
      }
    }
  }
`;

export const PRODUCT_BY_SKU = gql`
  query GetProductBySku($sku: String!) {
    getProductBySku(sku: $sku) {
      uid
      sku
      name
      description
      imageUrl
      wIsbn
      wBookTotalPages
      wBookPublishDate
      wBookAuthors {
        uid
        wName
      }
      pricPrice {
        edges {
          mapsOn {
            code
          }
          node {
            amount
          }
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query getSubscriptionsForCustomer(
    $filter: String!
    $orderBy: [FilterSubscriptionContractOrderByInput!]
  ) {
    filterViaxSubscriptionContractBy(
      _filter: $filter
      _page: { page: 1, limit: 100 }
      _orderBy: $orderBy
    ) {
      page {
        biId
        bpStatus {
          code
        }
        paybNextBillDate
        subsTerminationDate
        hiConsistsOf {
          biiProduct {
            sku
            #            name
            #            imageUrl
          }
          paybPayment {
            paymAmount(_mapsOn: [{ code: "TOTAL" }]) {
              edges {
                mapsOn {
                  code
                }
                node {
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query getProducts($sku: [String!]) {
    getProductBySkus(sku: $sku) {
      sku
      name
      imageUrl
    }
  }
`;

export const GET_VITAL_SOURCE = gql`
  query wGetVitalSourceAccessLink120sec($subscriptionContractBiId: String!) {
    wGetVitalSourceAccessLink120sec(
      subscriptionContractBiId: $subscriptionContractBiId
    )
  }
`;
