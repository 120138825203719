// Modules
import React, { useEffect } from 'react';

// CSS and assets
import './style.scss';
import heroImg1 from '@assets/images/Hero_Img_1.png';
import heroImg2 from '@assets/images/Hero_Img_2.png';
import panelImg1 from '@assets/images/Panel_Img_1.png';
import panelImg2 from '@assets/images/Panel_Img_2.png';
import panelImg3 from '@assets/images/Panel_Img_3.png';
import panelImg4 from '@assets/images/Panel_Img_4.png';
import panelImg5 from '@assets/images/Panel_Img_5.png';
import panelImg6 from '@assets/images/Panel_Img_6.png';

// Components
import HeroImage from '../components/HeroImage';
import ThreePanel from '../components/ThreePanel';
import TextPanel from '../components/TextPanel';
import StackablePanel from '../components/StackablePanel';
import Collapsible from '../components/Collapsible';
import ButtonCTA from '@src/global/components/UI Elements/ButtonCTA';

// Utils
import { Pages } from '@src/global/App/constants/constants';

const Pdp1: React.FC = () => {
  // Scroll to top of page on render.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const threePanelInfo = [
    {
      imageURL: panelImg1,
      title: 'REAL VALUE',
      contentText:
        'New titles and editions are added twice a year, helping you avoid unnecessary costs or time-consuming searches for additional resources.',
      ctaText: 'CTA Checkout',
    },
    {
      imageURL: panelImg2,
      title: 'EASY ACCESS',
      contentText:
        'Enjoy 24/7 access across multiple devices, or download our content for offline access so you can study anywhere.',
      learnMoreURL: Pages.PDP2,
    },
    {
      imageURL: panelImg3,
      title: 'LASTING SUCCESS',
      contentText:
        'With more than 200 titles, our curated eTextbook collection covers key topics, foundational concepts and adjacent subject areas that span the whole educational journey.',
      learnMoreURL: Pages.PDP3,
    },
  ];

  return (
    <div className="pdp-1">
      <HeroImage size="lg" imageURL={heroImg2} alt="Alt text" contentOffset>
        <h3>
          Wherever you are, whatever you need, it&apos;s easy to learn with
          Wiley Chemistry on Demand.
        </h3>
        <p>
          Introducing Wiley Chemistry on Demand, Wiley&apos;s affordable and
          accessible eTextbook subscription — featuring a world-class collection
          of content from the leading publisher in chemistry. From undergraduate
          studies to the Ph.D. level and beyond, Wiley Chemistry on Demand has
          you covered.
        </p>
      </HeroImage>

      <ThreePanel panelInfo={threePanelInfo} />

      <div className="pdp-1__stack-panel-wrapper">
        <TextPanel>
          <h3>Here&apos;s a look at what&apos;s next</h3>
          <p>
            Wherever you choose to study, you can count on Wiley Chemistry on
            Demand&apos;s easy subscription-based approach for fast access to
            the latest content from award-winning authors.
          </p>
        </TextPanel>

        <StackablePanel imageURL={panelImg4} alt="Alt text">
          <h3>REGISTER</h3>
          <p>
            It&apos;s easy to sign up and save with our simple monthly
            subscription and no-hassle checkout and payment methods.
          </p>
        </StackablePanel>
        <StackablePanel imageURL={panelImg5} alt="Alt text" learnMoreURL="/">
          <h3>CONNECT</h3>
          <p>
            There&apos;s no need to decide which books to buy — all of your
            content is just a click away with your Wiley Chemistry on Demand
            subscription.
          </p>
        </StackablePanel>
        <StackablePanel imageURL={panelImg6} alt="Alt text" learnMoreURL="/">
          <h3>LEARN</h3>
          <p>
            Search across our entire collection, save notes and bookmarks in
            your own workbook, and study at your own pace.
          </p>
        </StackablePanel>
      </div>

      <HeroImage size="sm" imageURL={heroImg1} alt="Alt text">
        <h3>A short, one-line title.</h3>
        <ButtonCTA variant="secondary">CTA Checkout</ButtonCTA>
      </HeroImage>

      <div className="pdp-1__faq-wrapper">
        <div className="pdp-1__faq-container">
          <TextPanel>
            <h3>Frequently Asked Questions</h3>
            <p>
              If you can&apos;t find the answers you need below, [support
              contact information]
            </p>
          </TextPanel>

          <Collapsible title="What is Wiley Chemistry on Demand?">
            The answer to the FAQ entry can be one or more sentences or
            paragraphs of text. If we anticipate a very long answer to the
            question, see if we can split into multiple entries. People skim
            rather than read!
          </Collapsible>
          <Collapsible title="How much content is available through Wiley Chemistry on Demand?">
            The answer to the FAQ entry can be one or more sentences or
            paragraphs of text. If we anticipate a very long answer to the
            question, see if we can split into multiple entries. People skim
            rather than read!
          </Collapsible>
          <Collapsible title="How do I sign up?">
            The answer to the FAQ entry can be one or more sentences or
            paragraphs of text. If we anticipate a very long answer to the
            question, see if we can split into multiple entries. People skim
            rather than read!
          </Collapsible>
          <Collapsible title="How much does a Wiley Chemistry on Demand subscription cost?">
            The answer to the FAQ entry can be one or more sentences or
            paragraphs of text. If we anticipate a very long answer to the
            question, see if we can split into multiple entries. People skim
            rather than read!
          </Collapsible>
          <Collapsible title="How can I access Wiley Chemistry on Demand content?">
            The answer to the FAQ entry can be one or more sentences or
            paragraphs of text. If we anticipate a very long answer to the
            question, see if we can split into multiple entries. People skim
            rather than read!
          </Collapsible>
          <Collapsible title="Can I use Wiley Chemistry on Demand without an internet connection?">
            The answer to the FAQ entry can be one or more sentences or
            paragraphs of text. If we anticipate a very long answer to the
            question, see if we can split into multiple entries. People skim
            rather than read!
          </Collapsible>
        </div>
      </div>
    </div>
  );
};

export default Pdp1;
