// Modules
import React, { useEffect } from 'react';

// CSS and assets
import './style.scss';
import heroImg1 from '@assets/images/Hero_Img_1.png';
import panelImg7 from '@assets/images/Panel_Img_7.png';
import panelImg8 from '@assets/images/Panel_Img_8.png';
import panelImg9 from '@assets/images/Panel_Img_9.png';
import panelImg10 from '@assets/images/Panel_Img_10.png';
import panelImg11 from '@assets/images/Panel_Img_11.png';

// Components
import HeroImage from '../components/HeroImage';
import TextPanel from '../components/TextPanel';
import StackablePanel from '../components/StackablePanel';
import StackablePanelFullBleedImg from '../components/StackablePanelFullBleedImg';
import FourPanel from '../components/FourPanel';
import Collapsible from '../components/Collapsible';
import ButtonCTA from '@src/global/components/UI Elements/ButtonCTA';

const Pdp2: React.FC = () => {
  // Scroll to top of page on render.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fourPanelInfo = [
    {
      imageURL: panelImg7,
      title: 'Panel Title 1',
      contentText:
        'Access 24/7 through multiple devices, from your laptop, tablet and phone. Download with 100% offline access.',
    },
    {
      imageURL: panelImg8,
      title: 'Panel Title 2',
      contentText:
        'Search across the collection to quickly find and view the information you need',
    },
    {
      imageURL: panelImg9,
      title: 'Panel Title 3',
      contentText:
        'Easily save your annotations, highlights, bookmarks and flashcards in your own Workbook',
    },
    {
      imageURL: panelImg7,
      title: 'Panel Title 4',
      contentText:
        'Supports accessible learning through audio function, font choices, and content controls',
    },
  ];

  return (
    <div className="pdp-2">
      <HeroImage size="md" imageURL={heroImg1} alt="Alt text">
        <h3>The Wiley Advantage</h3>
        <p>
          Access 24/7 through multiple devices, from your laptop, tablet and
          phone. Download with 100% offline access, so you can learn anywhere.
        </p>
      </HeroImage>

      <TextPanel>
        <h3>Wiley VCH</h3>
        <p>
          Quality guaranteed from the #1 publisher in chemistry. Wherever you
          choose to study, access world-class content from our international
          award-winning authors.
        </p>
      </TextPanel>

      <div className="pdp-2__stack-panel-wrapper">
        <StackablePanel imageURL={panelImg11} alt="Alt text">
          <h3>Years of Success</h3>
          <p>
            One or two sentences of content to describe the title. Can also
            serve to highlight what information the user should take away from
            this section
          </p>
        </StackablePanel>
        <StackablePanel imageURL={panelImg11} alt="Alt text">
          <h3>The Partner You Need</h3>
          <p>
            One or two sentences of content to describe the title. Can also
            serve to highlight what information the user should take away from
            this section
          </p>
        </StackablePanel>
      </div>

      <StackablePanelFullBleedImg imageURL={panelImg10} alt="Alt text">
        <h3>A Complete, Supported Experience</h3>
        <p>
          One or two sentences of content to describe the title. Can also serve
          to highlight what information the user will learn more about if they
          click the title or Learn More CTA.
        </p>
        <ButtonCTA variant="primary">CTA Checkout</ButtonCTA>
      </StackablePanelFullBleedImg>

      <TextPanel>
        <h3>Top Titles</h3>
        <p>
          Quality guaranteed from the #1 publisher in chemistry. Wherever you
          choose to study, access world-class content from our international
          award-winning authors.
        </p>
      </TextPanel>

      <FourPanel panelInfo={fourPanelInfo} />

      <HeroImage size="sm" imageURL={heroImg1} alt="Alt text">
        <h3>A short, one-line title.</h3>
        <ButtonCTA variant="secondary">CTA Checkout</ButtonCTA>
      </HeroImage>

      <div className="pdp-2__faq-wrapper">
        <div className="pdp-1__faq-container">
          <TextPanel>
            <h3>Frequently Asked Questions</h3>
            <p>
              If you can&apos;t find the answers you need below, [support
              contact information]
            </p>
          </TextPanel>

          <Collapsible title="FAQ entry which only takes up one line?">
            The answer to the FAQ entry can be one or more sentences or
            paragraphs of text. If we anticipate a very long answer to the
            question, see if we can split into multiple entries. People skim
            rather than read!
          </Collapsible>
          <Collapsible title="A longer FAQ entry which needs to wrap onto a second line due to length?">
            The answer to the FAQ entry can be one or more sentences or
            paragraphs of text. If we anticipate a very long answer to the
            question, see if we can split into multiple entries. People skim
            rather than read!
          </Collapsible>
          <Collapsible title="Try to avoid having any FAQ entries that are longer than these two lines, to improve text scannability?">
            The answer to the FAQ entry can be one or more sentences or
            paragraphs of text. If we anticipate a very long answer to the
            question, see if we can split into multiple entries. People skim
            rather than read!
          </Collapsible>
          <Collapsible title="FAQ entry which only takes up one line?">
            The answer to the FAQ entry can be one or more sentences or
            paragraphs of text. If we anticipate a very long answer to the
            question, see if we can split into multiple entries. People skim
            rather than read!
          </Collapsible>
        </div>
      </div>
    </div>
  );
};

export default Pdp2;
