// Modules
import React, { Children, ReactChild } from 'react';

// CSS and assets
import './style.scss';

const TextPanel: React.FC = ({ children }) => {
  const childrenArr = Children.toArray(children);

  const title = childrenArr.filter((el: ReactChild) => el['type'] === 'h3')[0];
  const content = childrenArr.filter((el: ReactChild) => el['type'] === 'p')[0];

  return (
    <div className="text-panel-wrapper">
      <div className="text-panel">
        {title}
        {content}
      </div>
    </div>
  );
};

export default TextPanel;
