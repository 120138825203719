import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  item: {
    name: string;
    link: string;
    type: string;
  };
}

const RoutingElement: React.FC<IProps> = ({ item: { name, link, type } }) => {
  if (type === 'int') {
    return <Link to={link}>{name}</Link>;
  } else if (type === 'ext') {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        {name}
      </a>
    );
  }
};

export default RoutingElement;
