import { gql } from '@apollo/client';

export const UPDATE_BILLING_INFO_MUTATION = gql`
  mutation updateSubscriptionContract(
    $biId: String!
    $uid: UUID
    $nameCC: String!
    $state: String!
    $addr1: String!
    $addr2: String!
    $zip: String!
    $city: String!
    $phone: String!
  ) {
    updateViaxSubscriptionContract(
      input: {
        biId: $biId
        wContactPhone: $phone
        paybBillingAddress: {
          typeName: "ViaxUrbanPropertyAddress"
          gadCountry: {
            name: "United States of America"
            numericCode: "840"
            iso2Code: "US"
            iso3Code: "USA"
          }
          gadStateOrProvince: $state
          upaLine1: $addr1
          upaLine2: $addr2
          upaPostcode: $zip
          upaCity: $city
        }
        paybPayment: {
          uid: $uid
          typeName: "StripeCustomerPeriodicCreditCardPayment"
          _stripeCustomerPeriodicCreditCardPayment: { ccpaNameOnCard: $nameCC }
        }
      }
    ) {
      biId
    }
  }
`;
