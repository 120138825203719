import React from 'react';
import '@global/components/NewsSignUpPanel/style.scss';
import { useTranslation } from 'react-i18next';

const NewsSignUpPanel: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="news-panel">
      <div className="news-panel__container">
        <span className="news-panel__title">{t('footer.news.title')}</span>

        <div className="news-panel__content">
          <div className="news-panel__input">
            <input type="email" placeholder="Enter your email address"></input>
            <button>Sign Up</button>
          </div>

          <span>
            By clicking “sign-up” you agree to receive emails, promotions and
            general messages from Wiley. In addition, you also agree to the
            Wiley’s privacy policy and Terms of use.
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewsSignUpPanel;
