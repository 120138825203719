import React from 'react';
import { useQuery } from '@apollo/client';

// CSS and assets
import defaultImage from '@assets/images/product-default-img.png';

// Components
import SubscriptionsBookItem from '@global/components/SubscriptionsBookItem';

// GraphQL
import { PRODUCT_BY_SKU } from '../../queries';

interface Props {
  sku: string;
  isActive?: boolean;
  totalPrice: string;
  periodInfo?: {
    subscriptionBegan: string;
    nextBillDate: string;
    subscriptionEndDate: string;
  };
}

const SubscriptionProduct: React.FC<Props> = ({
  sku,
  isActive,
  periodInfo,
  totalPrice,
}) => {
  const { data: data_query } = useQuery(PRODUCT_BY_SKU, {
    variables: { sku },
  });

  const productContents = data_query?.getProductBySku;

  const itemData = {
    imgUrl: productContents?.imageUrl || defaultImage,
    navEntity: {
      to: 'active-subscriptions',
      isInAppNav: true,
      label: productContents?.name,
    },
    bottomContent: {
      top: {
        left: isActive ? 'Next Bill Date' : 'Subscription End Date',
        right: isActive ? 'Recurring Monthly Bill' : '',
      },
      bottom: {
        left: isActive
          ? periodInfo.nextBillDate
          : periodInfo.subscriptionEndDate,
        right: isActive ? totalPrice : '',
      },
    },
  };

  return <SubscriptionsBookItem itemEntity={itemData} active={isActive} />;
};

export default SubscriptionProduct;
