// Modules
import React from 'react';
import { Link } from 'react-router-dom';

// CSS and assets
import './style.scss';

// Components
import ButtonCTA from '@global/components/UI Elements/ButtonCTA';

// Utils
import { Pages } from '@src/global/App/constants/constants';

interface IProps {
  panelInfo: {
    imageURL: string;
    title: string;
    contentText: string;
    ctaText?: string;
    learnMoreURL?: string;
  }[];
}
const TriplePanel: React.FC<IProps> = ({ panelInfo }) => {
  return (
    <div className="tri-panel">
      {panelInfo.map((el) => (
        <div key={el.title} className="tri-panel__panel-wrapper">
          <div className="tri-panel__img-container">
            <img
              className="tri-panel__panel-img"
              src={el.imageURL}
              alt={el.title}
            />
          </div>
          <div className="tri-panel__content-wrapper">
            <span className="tri-panel__title">{el.title}</span>
            <span className="tri-panel__content">{el.contentText}</span>
            {el.learnMoreURL && (
              <Link className="tri-panel__learn-more" to={el.learnMoreURL}>
                Learn more
              </Link>
            )}
            {el.ctaText && (
              <Link to={Pages.CHECKOUT}>
                <ButtonCTA variant="secondary">{el.ctaText}</ButtonCTA>
              </Link>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TriplePanel;
