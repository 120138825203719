export const prefixPath = (path: string, prefix: string) => {
  return `/${prefix}${path}/`;
};

export const localizeRoutes = (
  routes: {
    path: string;
    component?: unknown;
    localize?: boolean;
  }[]
) => {
  return routes.map((route) => {
    if (route.localize !== false) {
      return {
        ...route,
        path: prefixPath(route.path, ':locale'),
      };
    }
    return { ...route };
  });
};

/**
 * Checks if supported language already exists in path.
 * If it does return true, else false
 */
export const langExistsInPath = (
  supportedLngs: { code: string; lang: string }[]
) => {
  const currentLang = window.location.pathname
    .split('/')
    .filter((v) => v.length)
    .shift();
  return supportedLngs.map((lang) => lang.code).indexOf(currentLang) !== -1;
};

export const getCurrentPath = () => {
  return window.location.pathname + window.location.search;
};
